import { withStyles } from "@material-ui/core/styles";
import PersonalInfoSingleWill, { useStyle_singleWill as s_useStyle } from "./PersonalInfoSingleWill.web";
import PersonalInfoMirrorWill, { useStyle_Mirror as m_useStyle } from "./PersonalInfoMirrorWill.web";
import Executors, { useStyle as e_useStyle } from "./Executors.web";
import FuneralArrangments, { useStyle_funeral as f_useSTyle } from "./FuneralArrangments.web";
import ExemptionsToYourWill, { useStyle_exemption as exem_useSTyle } from "./ExemptionsToYourWill.web";
import Pets, { useStyle_pets as p_useSTyle } from "./Pets.web";
import Residue, { useStyle_residue as r_useSTyle } from "./ResidueEstate.web";
import LeaveaGift, { useStyle_leaveGift as l_useSTyle } from "./LeaveaGift.web";
import AppointingGuardian, { useStyle_appointing as a_useSTyle } from "./AppointingGuardian.web";


export const PersonalInfoSingleWillWeb = withStyles(s_useStyle)(PersonalInfoSingleWill)
export const PersonalInfoMirrorWillWeb = withStyles(m_useStyle)(PersonalInfoMirrorWill)
export const ExecutorsWeb = withStyles(e_useStyle)(Executors)
export const FuneralArrangmentsWeb = withStyles(f_useSTyle)(FuneralArrangments)
export const ExemptionsToYourWillWeb = withStyles(exem_useSTyle)(ExemptionsToYourWill)
export const PetsWeb = withStyles(p_useSTyle)(Pets)
export const ResidueWeb = withStyles(r_useSTyle)(Residue)
export const LeaveaGiftWeb = withStyles(l_useSTyle)(LeaveaGift)
export const AppointingGuardianWeb = withStyles(a_useSTyle)(AppointingGuardian)