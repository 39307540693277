import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { Modal, ModalBody } from "reactstrap";
export const configJSON = require("./config");



export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";

export class Residue extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerResidue, ResidueEstateStateData, ResidueDataHandler, ResidueEstateDataError, createCustomFormApiCall, modalOpenState, handleCloseThankyouModal,addResiduePersons,removeResiduePersons,ResiduePersonsHandler } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headingStyle}>
                    {configJSON.wishTorelieveQuestion}
                  </Typography>
                  <FormControl variant="filled" className={classes.selectFieldRight}>
                    <StyledSelectFieldResidue 
                      disableUnderline
                      renderValue={(selected: any) => {
                        return selected;
                      }}
                      value={ResidueEstateStateData.residue_estate}
                      name="residue_estate"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResidueDataHandler?.(e) }}
                      data-test-id="residue_estate">
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </StyledSelectFieldResidue>
                  </FormControl>
                  <Box className={classes.errorMessage}>
                    {ResidueEstateDataError.residue_estate !== "" && <div className={classes.errorTextStyle}>{ResidueEstateDataError.residue_estate}</div>}
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headingStyle}>
                    Who Would you like your estate be distributed to?
                  </Typography>
                </Grid>

              {
                ResidueEstateStateData.residue_estate_person_info?.map((item:{[key:string]:string},index:number)=>{
                  return(
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}
                          className={classes.removeGridContainer} key={index}>
                          <Box className={classes.removeContainer}>
                              <Typography
                                className={classes.personHeading}>
                                Person {1+index}
                              </Typography>
                              {ResidueEstateStateData.residue_estate_person_info.length!==1 &&
                              <Button
                              className={classes.removeBtn}
                              style={{ 
                                textTransform: "none",
                                float: "inline-end",
                                fontWeight: "initial",}}
                              type="button"
                              onClick={()=>{removeResiduePersons?.(index)}}
                              data-test-id="removeButton">
                                {configJSON.removeBtnName}
                              </Button>
                            }
                          </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldResidue
                        multiline
                        id="standard-textarea"
                        label="Name"
                        value={item['estate_person_name'+(index+1)]}
                        name={`estate_person_name${1+index}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResiduePersonsHandler?.(e,index) }}
                        type="text"
                        InputProps={{ disableUnderline: true, className: classes.input }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id={`estate_person_name${1+index}`}
                      />
                      <Box className={classes.errorMessage}>
                        {ResidueEstateDataError.residue_estate_person_info[index]['estate_person_name'+(index+1)] !== "" && <div className={classes.errorTextStyle}>{ResidueEstateDataError.residue_estate_person_info[index]['estate_person_name'+(index+1)]}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldResidue
                        multiline
                        id="standard-textarea"
                        label="Address"
                        value={item['estate_person_address'+(index+1)]}
                        name={`estate_person_address${1+index}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResiduePersonsHandler?.(e,index) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id={`estate_person_address${1+index}`}
                      />
                      <Box className={classes.errorMessage}>
                        {ResidueEstateDataError.residue_estate_person_info[index]['estate_person_address'+(index+1)] !== "" && <div className={classes.errorTextStyle}>{ResidueEstateDataError.residue_estate_person_info[index]['estate_person_address'+(index+1)]}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldResidue
                        multiline
                        id="standard-textarea"
                        label="Share amount"
                        value={item['estate_person_amount'+(index+1)]}
                        name={`estate_person_amount${1+index}`}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResiduePersonsHandler?.(e,index) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id={`estate_person_amount${1+index}`}
                      />
                      <Box className={classes.errorMessage}>
                      {ResidueEstateDataError.residue_estate_person_info[index]['estate_person_amount'+(index+1)] !== "" && <div className={classes.errorTextStyle}>{ResidueEstateDataError.residue_estate_person_info[index]['estate_person_amount'+(index+1)]}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.addBtnContainer}>
                        <Button 
                        className={classes.addBtn}
                        style={{fontWeight: "initial",textTransform: "none",}}
                        onClick={()=>{addResiduePersons?.()}} 
                        data-test-id="AddButton"
                        >
                          +{configJSON.addBtnName}
                        </Button> 
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <hr style={{ opacity: 0.1 }} />
                    </Grid>
                  </>
                  )
                })
              }
                
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormGroup className={classes.formGroupStyle}>
                    <FormControlLabel
                      control={<Checkbox checked={ResidueEstateStateData.for_Myself} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResidueDataHandler?.(e) }} name="for_Myself" className={classes.checkBoxStyle}/>}
                      label={<Typography className={classes.checkBoxLableStyle}>I confirm that this will is for myself and I am and not being unduly influenced</Typography>}
                      data-test-id="checkboxLabelMyself"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={ResidueEstateStateData.haveMental_Capacity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResidueDataHandler?.(e) }} name="haveMental_Capacity" className={classes.checkBoxStyle} data-test-id="haveMental_Capacity" />}
                      label={<Typography className={classes.checkBoxLableStyle}>I confirm that I have mental capacity and understand that I am creating a will</Typography>}
                      data-test-id="checkboxLabelMentalCapacity"
                    />
                     <FormControlLabel
                      control={<Checkbox checked={ResidueEstateStateData.capacity_Vault} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ResidueDataHandler?.(e) }} name="capacity_Vault" className={classes.checkBoxStyle} data-test-id="capacity_Vault" />}
                      label={<Typography className={classes.checkBoxLableStyle}>Please tick this box if you would like access to capacity vault</Typography>}
                      data-test-id="checkboxLabelCapacityVault"
                    />
                  </FormGroup>
                  <Box className={classes.errorMessage}>
                    {(ResidueEstateDataError.for_Myself == "" || ResidueEstateDataError.for_Myself == null) && (ResidueEstateDataError.haveMental_Capacity == ""  || ResidueEstateDataError.haveMental_Capacity == null) ? null : <div className={classes.errorTextStyle}><p>please tick above confirm checkbox</p></div>}
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className={classes.btnCollection}>
                    <Button
                      className={classes.btnStyle}
                      style={{
                        textTransform: "none"
                      }}
                      onClick={() => { nextBtnHandler?.("Pets") }}
                      data-test-id="PreviousBtn">
                      Previous
                    </Button>
                    <Button
                      className={classes.btnStyle}
                      style={{
                        textTransform: "none"
                      }}
                      disabled={nextErrorBtnHandlerResidue?.(this.props)}
                      onClick={() => { createCustomFormApiCall?.() }}
                      data-test-id="SubmitBtn"
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>

              </Grid>
            </form>

            <Modal 
              isOpen={modalOpenState}
              toggle={handleCloseThankyouModal}
              modalTransition={{ timeout: 200 }}
              data-test-id="Thankyou_modal">
              <ModalBody>
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <img
                    src={require("../../ordersummary/assets/tick.svg")}
                    className="img-fluid yt-order-placed-icn"
                    width="170"
                    height="212"
                  />
                  <div className="orderplc-wrap mt-4">
                    <h4 className="fw-bold my-3">
                      Thank You
                    </h4>
                    <p className="mb-0 orderplc-text">
                      Order placed successfully
                    </p>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_residue = (theme: any) => ({

  fullWidthTextField: {
    width: "98%",
    margin: "8px",
    marginTop: "24px",
  },
  selectFieldRight: {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  headingStyle: {
    padding: "2% 0",
    color: "#000 !important"
  },
  personHeading: {
    padding: "10px 10px 5px 10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  textCustomStyle: {
    border: "1px solid #D3D3D3",
    padding: "6px"
  },
  ageTextStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px",
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5%"
  },
  checkBoxStyle: {
    color: "#364F6B !important"
  },
  checkBoxLableStyle: {
    color: "#364F6B !important"
  },
  formGroupStyle: {
    padding: "10px 0px !important"
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  },
  addBtnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addBtn:{
    fontSize: "medium",
    color: "#000 !important",
  },
  removeGridContainer:{
    padding: "8px 8px 0px 8px !important"
  },
  removeContainer:{
    display: "flex",
    justifyContent: "space-between"
  },
  removeBtn:{
    fontSize: "medium",
    color: "red"
  },
});

export const StyledTextFieldResidue: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px !important",
    border: "1px solid black",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldResidue: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);


export default withStyles(useStyle_residue)(Residue)
// Customizable Area End
