import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import {PersonalInfoSingleWillWeb,PersonalInfoMirrorWillWeb,ExecutorsWeb,FuneralArrangmentsWeb,
  ExemptionsToYourWillWeb,PetsWeb,ResidueWeb,LeaveaGiftWeb,AppointingGuardianWeb} from "./FormWithStyleWrapperweb";


export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";

export default class CustomFormStepContainer extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getColor = (name: string) => {
    if (this.state.formFlag === name) {
      return '#364F6B';
    }
    else {
      return 'rgba(0, 0, 0, 0.54)';
    }
  }

  getFontWeight = (name: string) => {
    if (this.state.formFlag === name) {
      return 'bold';
    }
    else {
      return 'normal';
    }
  }

  getComponentHandler = () => {
    switch (this.state.formFlag)
     {
      case "PersonalInfo1":
        if(this.state.cartProduct[0]?.attributes?.product_name==="Mirror Will")
        {
          return(
            <Box>              
              <PersonalInfoMirrorWillWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} PersonalInfoStateData={this.state.PersonalInfoData} userInputHandler={this.userInputHandler} PersonalInfoDataError={this.state.PersonalInfoDataError} nextErrorBtnHandlerPersonMirror={this.nextErrorBtnHandlerPersonMirror}/>
            </Box>
          )
        }
        else if(this.state.cartProduct[0]?.attributes?.product_name==="Single Will")
        {
          return (
            <Box>
              <PersonalInfoSingleWillWeb {...this.props} data-testId="SingleWill" navigation={() => { }} id="" nextBtnHandler={this.showForm} PersonalInfoDataStateSingle={this.state.PersonalInfoDataSingle} userInputHandlerSingle={this.userInputHandlerSingle} PersonalInfoDataSingleError={this.state.PersonalInfoDataSingleError} nextErrorBtnHandlerPersonalSingle={this.nextErrorBtnHandlerPersonalSingle}/>
            </Box>
          )
        }
        else{
          return null;
        }
       
      case "Executors":
        return (
          <Box>
            <ExecutorsWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} ExecutorsStateData={this.state.ExecutorsData} userInputHandlerExecutor={this.userInputHandlerExecutor} ExecutorsDataError={this.state.ExecutorsDataError} nextErrorBtnHandlerExecutors={this.nextErrorBtnHandlerExecutors} addExecutorsHandler={this.addExecutorsHandler} removeExecutorsHandler={this.removeExecutorsHandler} userInputHandlerExecutorList={this.userInputHandlerExecutorList}/>
          </Box>
        )
      case "FuneralArrangments":
        return (
          <Box>
            <FuneralArrangmentsWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} FuneralArrangementStateData={this.state.FuneralArrangementData} userInputHandlerFuneral={this.userInputHandlerFuneral} FuneralArrangementDataError={this.state.FuneralArrangementDataError} nextErrorBtnHandlerFuneral={this.nextErrorBtnHandlerFuneral}/>
          </Box>
        )
      case "AppointingGuardian":
        return (
          <Box>
            <AppointingGuardianWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} AppointingGuardianStateData={this.state.AppointingGuardianData} AppointingGuardianHandler={this.AppointingGuardianHandler} AppointingGuardianDataError={this.state.AppointingGuardianDataError} nextErrorBtnHandlerAppointing={this.nextErrorBtnHandlerAppointing}/>
          </Box>
        )
      case "LeaveaGift":
        return (
          <Box>
            <LeaveaGiftWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} LeaveGiftStateData={this.state.LeaveGiftData} LeaveGiftHandler={this.LeaveGiftHandler} LeaveGiftDataError={this.state.LeaveGiftDataError} nextErrorBtnHandlerLeaveGift={this.nextErrorBtnHandlerLeaveGift} addGiftMoneyHandler={this.addGiftMoneyHandler} removeGiftsMoneyHandler={this.removeGiftsMoneyHandler} LeaveGiftMoneyListHandler={this.LeaveGiftMoneyListHandler} addGiftsHandler={this.addGiftsHandler} removeGiftsHandler={this.removeGiftsHandler} LeaveGiftListHandler={this.LeaveGiftListHandler} addRecipientHandler={this.addRecipientHandler} removeRecipientHandler={this.removeRecipientHandler} LeaveRecipientListHandler={this.LeaveRecipientListHandler} LeavePropertyListHandler={this.LeavePropertyListHandler} addProperty={this.addProperty} removeProperty={this.removeProperty} />
          </Box>
        )
      case "ExemptionsToYourWill":
        return (
          <Box>
            <ExemptionsToYourWillWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} ExemptionStateData={this.state.ExemptionData} ExemptionDataHandler={this.ExemptionDataHandler} ExemptionDataError={this.state.ExemptionDataError} nextErrorBtnHandlerExemption={this.nextErrorBtnHandlerExemption}/>
          </Box>
        )
      case "Pets":
        return (
          <Box>
            <PetsWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} PetsStateData={this.state.PetsData} PetsDataHandler={this.PetsDataHandler} PetsDataError={this.state.PetsDataError} nextErrorBtnHandlerPets={this.nextErrorBtnHandlerPets}/>
          </Box>
        )
      case "ResidueEstate":
        return (
          <Box>
            <ResidueWeb {...this.props} navigation={() => { }} id="" nextBtnHandler={this.showForm} ResidueEstateStateData={this.state.ResidueEstateData} ResidueDataHandler={this.ResidueDataHandler} ResidueEstateDataError={this.state.ResidueEstateDataError} createCustomFormApiCall={this.createCustomFormApiCall} handleCloseThankyouModal={this.handleCloseThankyouModal} modalOpenState={this.state.openThankyouModal} nextErrorBtnHandlerResidue={this.nextErrorBtnHandlerResidue} addResiduePersons={this.addResiduePersons} removeResiduePersons={this.removeResiduePersons} ResiduePersonsHandler={this.ResiduePersonsHandler}/>
          </Box>
        )
      default:
        return null;

    }

  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              style={{
                fontSize: "larger",
                textAlign: "center",
                padding: "3%",
                color: "#000"
              }}>Questions</Typography>

            <Breadcrumbs data-testId="breadcrumb" separator={<NavigateNextIcon fontSize="large" />} aria-label="breadcrumb"
              className={classes.styleBreadcrumb}
              itemsAfterCollapse={2}
              itemsBeforeCollapse={2}
              maxItems={5}>
              <Link color="inherit"
                style={{
                  color: this.getColor("PersonalInfo1"),
                  fontWeight: this.getFontWeight("PersonalInfo1")
                }}
              >
                Personal Information
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("Executors"),
                  fontWeight: this.getFontWeight("Executors")
                }}
              >
                Executors
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("FuneralArrangments"),
                  fontWeight: this.getFontWeight("FuneralArrangments")
                }}>
                Funeral arrangements
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("AppointingGuardian"),
                  fontWeight: this.getFontWeight("AppointingGuardian")
                }}>
                Appointing a Guardian
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("LeaveaGift"),
                  fontWeight: this.getFontWeight("LeaveaGift")
                }}>
                Leave a gift
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("ExemptionsToYourWill"),
                  fontWeight: this.getFontWeight("ExemptionsToYourWill")
                }}>
                Exemptions to your will
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("Pets"),
                  fontWeight: this.getFontWeight("Pets")
                }}>
                Pets
              </Link>
              <Link color="inherit"
                style={{
                  color: this.getColor("ResidueEstate"),
                  fontWeight: this.getFontWeight("ResidueEstate")
                }}>
                Residue Estate
              </Link>

            </Breadcrumbs>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {this.getComponentHandler()}
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_c = (theme: any) => ({
  styleBreadcrumb: {
    fontSize: "larger",
    padding: "6% 3%",
    "& .MuiBreadcrumbs-li": {
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiLink-underlineHover:hover": {
      textDecoration: "none"
    },
    mainHeading: {
      fontSize: "larger",
      textAlign: "center",
      padding: "3%",
      color: "#000"
    },
  },
  // linkStyleTrue: {
  //  color:'#364F6B !important',
  //  fontWeight:"bold !important"
  // },
  // linkStyleFalse: {
  //   color:'rgba(0, 0, 0, 0.54) !important',
  //   fontWeight:"normal !important"
  //  }
});

// export default withStyles(useStyle)(CustomFormStepContainer)
// export default CustomFormStepContainer;
export const CustomFormStepContainerWeb = withStyles(useStyle_c)(CustomFormStepContainer)

// Customizable Area End
