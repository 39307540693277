import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";
import { NextPreBtns } from "../../../components/src/CommonCustomForms";



export const f_theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End



export class FuneralArrangments extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }



  // Customizable Area Start

  propBasedRender = (property: any) => {
    switch (property.FuneralArrangementStateData.organs_donation) {
      case "Yes, all organs":
        return (
          this.allOrganHandler(property)
        )
      case "Yes, but only...":
        return (
          this.butOnlyHandler(property)
        )
      case "Yes, all organs except...":
        return (
          this.allOrgansExceptHandler(property)
        )
      default:
        return null;
    }

  }

  allOrganHandler = (handler: any) => {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={this.props.classes.headlineColorFuneral}>
          Yes, all organs
        </Typography>
        <FormControl variant="filled" className={this.props.classes.formControlCustomStyleFuneral}>
          <StyledSelectFieldFuneral 
            disableUnderline
            renderValue={(selected: any) => {
              return selected;
            }}
            value={this.props.FuneralArrangementStateData.Yes_all_organs}
            name="Yes_all_organs"
            onChange={(e: any) => { handler.userInputHandlerFuneral(e) }}
            data-test-id="Yes_all_organs_id"
          >
            <MenuItem value={"Medical and Scientific research"}>Medical and Scientific research</MenuItem>
            <MenuItem value={"Organ donation and therapeutic purposes"}>Organ donation and therapeutic purposes </MenuItem>

          </StyledSelectFieldFuneral>
        </FormControl>
        {this.props.FuneralArrangementDataError.Yes_all_organs !== "" && <div className={this.props.classes.errorTextStyle}>{this.props.FuneralArrangementDataError.Yes_all_organs}</div>}
      </Grid>
    )
  }

  butOnlyHandler = (handler: any) => {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={this.props.classes.headlineColorFuneral}>
          Which are the specific organs?
        </Typography>
        <StyledTextFieldFuneral
          multiline
          id="standard-textarea"
          label="Which are the specific organs?"
          name="specific_organs"
          value={this.props.FuneralArrangementStateData.specific_organs}
          type="text"
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{
            style: { color: "rgba(0, 0, 0, 0.54)" },
          }}
          className={this.props.classes.textCustomStyleFuneral}
          onChange={(e: any) => { handler.userInputHandlerFuneral(e) }}
          data-test-id="specific_organs_id"
        />
        {this.props.FuneralArrangementDataError.specific_organs !== "" && <div className={this.props.classes.errorTextStyle}>{this.props.FuneralArrangementDataError.specific_organs}</div>}
      </Grid>
    )
  }

  allOrgansExceptHandler = (handler: any) => {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={this.props.classes.headlineColorFuneral}>
          What purposes shall organs be made available for?
        </Typography>
        <FormControl variant="filled" className={this.props.classes.formControlCustomStyleFuneral}>
          <StyledSelectFieldFuneral 
            disableUnderline
            renderValue={(selected: any) => {
              return selected;
            }}
            value={this.props.FuneralArrangementStateData.organs_purpose}
            name="organs_purpose"
            onChange={(e: any) => { handler.userInputHandlerFuneral(e) }}
            data-test-id="organs_purpose_id"
          >
            <MenuItem value={"Medical and Scientific research"}>Medical and Scientific research</MenuItem>
            <MenuItem value={"Organ donation and therapeutic purposes"}>Organ donation and therapeutic purposes</MenuItem>

          </StyledSelectFieldFuneral>
        </FormControl>
        {this.props.FuneralArrangementDataError.organs_purpose !== "" && <div className={this.props.classes.errorTextStyle}>{this.props.FuneralArrangementDataError.organs_purpose}</div>}

      </Grid>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerFuneral, FuneralArrangementStateData, userInputHandlerFuneral, FuneralArrangementDataError } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={f_theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} className={classes.styleContainerFuneral}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.headlineColorFuneral}>
                        Do you want to specify any funeral arrangements?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleFuneral}>
                        <StyledSelectFieldFuneral 
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={FuneralArrangementStateData.specify_funeral_arrangement}
                          name="specify_funeral_arrangement"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerFuneral?.(e) }}
                          data-test-id="specify_funeral_arrangement"
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldFuneral>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {FuneralArrangementDataError.specify_funeral_arrangement !== "" && <div className={classes.errorTextStyle}>{FuneralArrangementDataError.specify_funeral_arrangement}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.headlineColorFuneral}>
                        Do you wish to be buried or cremated?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleFuneral}>
                        <StyledSelectFieldFuneral 
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={FuneralArrangementStateData.specify_buried_or_cremated}
                          name="specify_buried_or_cremated"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerFuneral?.(e) }}
                          data-test-id="specify_buried_or_cremated"
                          >
                          <MenuItem value={"Buried"}>Buried</MenuItem>
                          <MenuItem value={"Cremated"}>Cremated</MenuItem>
                          <MenuItem value={"No Preference"}>No Preference</MenuItem>
                        </StyledSelectFieldFuneral>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {FuneralArrangementDataError.specify_buried_or_cremated !== "" && <div className={classes.errorTextStyle}>{FuneralArrangementDataError.specify_buried_or_cremated}</div>}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <StyledTextFieldFuneral
                    multiline
                    id="standard-textarea"
                    label="Please state any other funeral requirements"
                    value={FuneralArrangementStateData.any_other_funeral_requirement}
                    name="any_other_funeral_requirement"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      style: { color: "rgba(0, 0, 0, 0.54)" },
                    }}
                    className={classes.textCustomStyleFuneral}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerFuneral?.(e) }}
                    data-test-id="any_other_funeral_requirement"
                  />
                  <Box className={classes.errorMessage}>
                    {FuneralArrangementDataError.any_other_funeral_requirement !== "" && <div className={classes.errorTextStyle}>{FuneralArrangementDataError.any_other_funeral_requirement}</div>}
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headlineColorFuneral}>
                    Would you like to make organs available for donation / scientific purposes?
                  </Typography>
                  <FormControl variant="filled" className={classes.formControlCustomStyleFuneral}>
                    <StyledSelectFieldFuneral 
                      disableUnderline
                      renderValue={(selected: any) => {
                        return selected;
                      }}
                      value={FuneralArrangementStateData.organs_donation}
                      name="organs_donation"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerFuneral?.(e) }}
                      data-test-id="organs_donation"
                      >
                      <MenuItem value={"Yes, all organs"}>Yes, all organs</MenuItem>
                      <MenuItem value={"Yes, but only..."}>Yes, but only... </MenuItem>
                      <MenuItem value={"Yes, all organs except..."}>Yes, all organs except... </MenuItem>
                      <MenuItem value={"No"}>No </MenuItem>
                    </StyledSelectFieldFuneral>
                  </FormControl>
                  <Box className={classes.errorMessage}>
                    {FuneralArrangementDataError.organs_donation !== "" && <div className={classes.errorTextStyle}>{FuneralArrangementDataError.organs_donation}</div>}
                  </Box>
                </Grid>
                {this.propBasedRender(this.props)}
                {
                  NextPreBtns({
                    PropValues:this.props,
                    nextBtnHandler:nextBtnHandler,
                    prevPage:"Executors",
                    nextPage:"AppointingGuardian",
                    nextErrorBtnHandler:nextErrorBtnHandlerFuneral
                  })
                }
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_funeral = (f_theme: any) => ({

  textCustomStyleFuneral: {
    border: "1px solid #D3D3D3",
    padding: "10px"
  },
  headLineFuneral: {
    padding: "10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  formControlCustomStyleFuneral: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  styleContainerFuneral: {
    // alignItems: "flex-end"
    alignItems: "baseline"
  },
  headlineColorFuneral: {
    padding: "2% 0",
    color: "#000 !important",
    height: "4rem !important"
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5%"
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }
});

export const StyledTextFieldFuneral: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px",
    border: "1px solid #D3D3D3",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldFuneral: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);

export default FuneralArrangments;

// Customizable Area End
