import React from "react";
import {
    Box,
    Typography,
    Button
} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { StyledSelectFieldLeaveGift } from "../../blocks/CustomForm2/src/LeaveaGift.web";
import { StyledSelectField } from "../../blocks/CustomForm2/src/PersonalInfoSingleWill.web";
import { StyledSelectFieldMirror } from "../../blocks/CustomForm2/src/PersonalInfoMirrorWill.web";




export const NextPreBtns = (props: { PropValues: {classes:{[key:string]:string}}; 
    nextBtnHandler: (undefined|((item:string)=>void|undefined)); prevPage: string; nextPage: string; 
    nextErrorBtnHandler: (undefined|((item:{classes:{[key:string]:string}})=>boolean))}) => {

    const { PropValues, nextBtnHandler, nextErrorBtnHandler, prevPage, nextPage } = props
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className={PropValues.classes.btnCollection}>
                <Button
                    className={PropValues.classes.btnStyle}
                    style={{
                        textTransform: "none",
                    }}
                    onClick={() => { nextBtnHandler?.(prevPage) }}
                    data-test-id="prevBtn"
                >
                    Previous
                </Button>
                <Button
                    className={PropValues.classes.btnStyle}
                    style={{
                        textTransform: "none",
                    }}
                    disabled={nextErrorBtnHandler?.(PropValues)}
                    onClick={() => { nextBtnHandler?.(nextPage) }}
                    data-test-id="nextBtn"
                >
                    Next
                </Button>
            </Box>
        </Grid>
    );
}


export const DropdownView = (props: { userInputHandler: (undefined|((item:React.ChangeEvent<HTMLInputElement>)=>void)); 
countryCode: string; country_code_name: string; PropValues: {classes:{[key:string]:string}}; 
StateValue: {}[]; }) => {

    const { PropValues, countryCode, country_code_name, userInputHandler, StateValue } = props
    return (
        <Grid item lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="filled" className={PropValues.classes.formControlCustomStyleMobileSingle}
                style={{
                    borderRadius: "0px 8px 8px 0px !important",
                    paddingBottom: "0px",
                }}>
                <StyledSelectField
                    disableUnderline
                    renderValue={(value: {[key:string]:string}) => {
                        return (
                            <Box
                                className={PropValues.classes.countryCodeContainer}>
                                <img src={value.path} className={PropValues.classes.countryFlag} />
                                {" " + value.name + " "}
                            </Box>
                        );
                    }}
                    value={countryCode}
                    name={country_code_name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(event) }}
                    data-test-id={`${country_code_name}-countryId`}
                >
                    {/* using type as any as there is limitation for value in menuItem which don't accept the object */}
                    {StateValue?.map((item:{[key:string]:string}|any) => {
                        return (
                            <MenuItem value={item} key={item.path}>
                                <Box className={PropValues.classes.countryCodeContainer}>
                                    <img src={item.path} className={PropValues.classes.countryFlag} />
                                    {" " + item.name + " "}
                                </Box>
                            </MenuItem>
                        )
                    })}
                </StyledSelectField>
            </FormControl>
        </Grid>
    );
}

export const DropdownViewForList = (props: { userInputHandlerList: (undefined|((item:React.ChangeEvent<HTMLInputElement>,
    index:number)=>void)); countryCode: string; country_code_name: string; PropValues: {classes:{[key:string]:string}}; 
    StateValue: {}[]; index: number;}) => {

    const { PropValues, countryCode, country_code_name, userInputHandlerList, StateValue, index } = props

    return (
        <Grid item lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="filled" className={PropValues.classes.formControlCustomStyleMobileSingle}
                style={{
                    borderRadius: "0px 8px 8px 0px !important",
                    paddingBottom: "0px",
                }}>
                <StyledSelectField
                    disableUnderline
                    renderValue={(value: { [key: string]: string }) => {
                        return (
                            <Box
                                className={PropValues.classes.countryCodeContainer}>
                                <img src={value.path} className={PropValues.classes.countryFlag} />
                                {" " + value.name + " "}
                            </Box>
                        );
                    }}
                    value={countryCode}
                    name={country_code_name}
                    onChange={(event:React.ChangeEvent<HTMLInputElement> ) => { userInputHandlerList?.(event, index) }}
                    data-test-id={`${country_code_name}-countryId`}
                >
                    {StateValue?.map((item:{[key:string]:string}|any) => {
                        return (
                        //using type as any as there is limitation for value in menuItem which don't accept the object
                            <MenuItem value={item} key={item.path}>
                                <Box className={PropValues.classes.countryCodeContainer}>
                                    <img src={item.path} className={PropValues.classes.countryFlag} />
                                    {" " + item.name + " "}
                                </Box>
                            </MenuItem>
                        )
                    })}
                </StyledSelectField>
            </FormControl>
        </Grid>
    );
}


export const Transport = (props: { PropValues: {classes:{[key:string]:string}}; 
    LeaveGiftListHandler: (undefined|((item:React.ChangeEvent<HTMLInputElement>,index:number)=>void));
     LeaveGiftDataError: {[key:string]:{[key:string]:string}[]}; showErrorMessageHandler: (errorType: string)=>JSX.Element; 
     transportStateValue: string; transportStateName: string; index: number; }) => {

    const { PropValues, LeaveGiftListHandler, LeaveGiftDataError, showErrorMessageHandler, transportStateValue,
        transportStateName, index } = props

    return (

        <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className={PropValues.classes.headlineColor}>
                If required How shall Transportation Costs be paid ?
            </Typography>
            <FormControl variant="filled" className={PropValues.classes.formControlCustomStyleLeaveGift}>
                <StyledSelectFieldLeaveGift
                    disableUnderline
                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                        return selected;
                    }}
                    value={transportStateValue}
                    name={transportStateName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftListHandler?.(event, index) }}
                    data-test-id="gift1_transportation_cost"
                >
                    <MenuItem value={"Transport / insurance expenses to be paid by the estate"}>Transport / insurance expenses to be paid by the estate</MenuItem>
                    <MenuItem value={"Transport / insurance expenses to be paid by the recipient"}>Transport / insurance expenses to be paid by the recipient</MenuItem>
                </StyledSelectFieldLeaveGift>
            </FormControl>
            <Box className={PropValues.classes.errorMessage}>
                {showErrorMessageHandler(LeaveGiftDataError.giftList[index].gift1_transportation_cost)}
            </Box>
        </Grid>
    );
}


export const InheritanceGiftList = (props: { PropValues: {classes:{[key:string]:string}}; 
    LeaveGiftListHandler: (undefined|((item:React.ChangeEvent<HTMLInputElement>,index:number)=>void));
LeaveGiftDataErrorMessage: string; showErrorMessageHandler: (errorType: string)=>JSX.Element; 
inheritanceStateValue: string; inheritanceStateName: string; index: number; }) => {

    const { PropValues, LeaveGiftListHandler, LeaveGiftDataErrorMessage, showErrorMessageHandler, inheritanceStateValue,
        inheritanceStateName, index } = props

    return (

        <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className={PropValues.classes.headlineColor}>
                How shall Inheritance Tax be paid?
            </Typography>
            <FormControl variant="filled" className={PropValues.classes.formControlCustomStyleLeaveGift}>
                <StyledSelectFieldLeaveGift
                    disableUnderline
                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                        return selected;
                    }}
                    value={inheritanceStateValue}
                    name={inheritanceStateName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { LeaveGiftListHandler?.(event, index) }}
                >
                    <MenuItem value={"IHT to be paid by the estate"}>IHT to be paid by the estate</MenuItem>
                    <MenuItem value={"IHT to be paid by the recipient"}>IHT to be paid by the recipient</MenuItem>
                </StyledSelectFieldLeaveGift>
            </FormControl>
            <Box className={PropValues.classes.errorMessage}>
                {showErrorMessageHandler(LeaveGiftDataErrorMessage)}
            </Box>
        </Grid>
    );
}


export const MaritalStatus = (props: { PropValues: {classes:{[key:string]:string}}; 
userInputHandler: (undefined|((event:React.ChangeEvent<HTMLInputElement>)=>void)); 
PersonalInfoDataError: string; stateValue: string; name: string; }) => {

    const { PropValues, userInputHandler, PersonalInfoDataError, stateValue, name } = props
    return (
        <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography className={PropValues.classes.headlineColor}>
                What is your marital status?
            </Typography>
            <FormControl variant="filled" className={PropValues.classes.formControlCustomStyle}>
                <StyledSelectFieldMirror
                    disableUnderline
                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                        return selected;
                    }}
                    value={stateValue}
                    name={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(event) }}
                    data-test-id={`${name}-Status`}>
                    <MenuItem value={"Civil Partnership"}>Civil Partnership</MenuItem>
                    <MenuItem value={"Single"}>Single</MenuItem>
                    <MenuItem value={"Married"}>Married</MenuItem>
                    <MenuItem value={"Widowed"}>Widowed</MenuItem>
                    <MenuItem value={"Divorced"}>Divorced</MenuItem>
                    <MenuItem value={"Cohabiting"}>Cohabiting</MenuItem>
                </StyledSelectFieldMirror>
            </FormControl>
            <Box className={PropValues.classes.errorMessage}>
                {PersonalInfoDataError !== "" && <div className={PropValues.classes.errorTextStyle}>{PersonalInfoDataError}</div>}
            </Box>
        </Grid>
        // Customizable Area End
    );
}
