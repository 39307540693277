Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.postMethodType = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomForm2";
exports.labelBodyText = "CustomForm2 Body";
exports.endPointApiGetIsCartCreated = "cart/carts";
exports.endPointApiCreateCustomForm = "custom_form";
exports.btnExampleTitle = "CLICK ME";
exports.wishTorelieveQuestion = "Does test wish to relieve anyone of debts upon test's death?"
exports.addBtnName = "Add";
exports.removeBtnName = "Remove";
exports.limitErrorMessage = "You can't add more than 4 people";
exports.petsCareQuestion = "Who is to care for your pets?";
exports.leaveMoneyGiftQuestion = "Would test like to leave a gift of money with any pets?";
// Customizable Area End