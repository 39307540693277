
export const arrayToJsonConvertor = (item) =>{
    let obj={};
    item.reduce((acc, currentItem)=>{
      const keys = Object.keys(currentItem);
      const values = Object.values(currentItem);
      for(let x = 0; x< keys.length; x++){
          let key = keys[x]
          let value = values[x]
          obj[key] = value
          console.log(key, value)
      }
      
  }, {})
    return obj;
}