// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    TextField,
    Box,
    Grid
} from "@material-ui/core";

export const StyledTextFieldLeaveGift: any = withStyles({
    root: {
        fontSize: "14",
        borderRadius: "8px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#ffffff !important",
        display: "block !important",
        "& #standard-textarea-label": {
            padding: "0px 0px 0px 10px",
        },
        "& .Mui-focused": {
            padding: "10px 0px 0px 10px !important",
        },
        "& #standard-textarea": {
            color: "#000 !important"
        }

    },
})((props: any) => <TextField {...props} />);

const CustomLeaveGiftTextField = (props: any) => {
    const {
        label,
        value,
        name,
        inputType,
        index,
        inputStyle,
        textFieldStyle,
        inputPropsStyles,
        onChangeHandler,
        dataTestId,
        errorMsg,
        errorMessageStyle } = props;
    return (
        <Grid item lg={6} md={6} sm={6} xs={12}>
            <StyledTextFieldLeaveGift
                multiline
                id="standard-textarea"
                label={label}
                value={value}
                name={name}
                type={inputType ?? "text"}
                InputProps={{ disableUnderline: true, className: inputStyle, ...inputPropsStyles }}
                InputLabelProps={{
                    style: { color: "rgba(0, 0, 0, 0.54)" },
                }}
                className={textFieldStyle}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangeHandler?.(event, index) }}
                data-test-id={dataTestId}
            />
            <Box className={errorMessageStyle}>
                {errorMsg}
            </Box>
        </Grid>
    )
}

export default CustomLeaveGiftTextField;

// Customizable Area End