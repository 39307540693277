import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomForm2Controller, {
  configJSON,
  Props,
} from "./CustomForm2Controller";
import { NextPreBtns } from "../../../components/src/CommonCustomForms";



export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End



export class Pets extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerPets, PetsStateData, PetsDataHandler, PetsDataError} = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headingStyle}>
                    Shall any provisions be made for pets?
                  </Typography>
                  <FormControl variant="filled" className={classes.selectFieldRight}>
                    <StyledSelectFieldPets 
                      disableUnderline
                      renderValue={(selected: any) => {
                        return selected;
                      }}
                      value={PetsStateData.provision_for_pets}
                      name="provision_for_pets"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                      data-test-id="provision_for_pets">
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </StyledSelectFieldPets>
                  </FormControl>
                  <Box className={classes.errorMessage}>
                    {PetsDataError.provision_for_pets !== "" && <div className={classes.errorTextStyle}>{PetsDataError.provision_for_pets}</div>}
                  </Box>
                </Grid>
                {PetsStateData.provision_for_pets === "Yes" &&
                  <>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.headingStyle}>
                        {configJSON.petsCareQuestion}
                      </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldPets
                        multiline
                        id="standard-textarea"
                        label="Name of Carer"
                        value={PetsStateData.name_of_carer}
                        name="name_of_carer"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true, className: classes.input }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id="name_of_carer"
                      />
                      <Box className={classes.errorMessage}>
                        {PetsDataError.name_of_carer !== "" && <div className={classes.errorTextStyle}>{PetsDataError.name_of_carer}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldPets
                        multiline
                        id="standard-textarea"
                        label="Address of carer"
                        value={PetsStateData.address_of_carer}
                        name="address_of_carer"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id="address_of_carer"
                      />
                      <Box className={classes.errorMessage}>
                        {PetsDataError.address_of_carer !== "" && <div className={classes.errorTextStyle}>{PetsDataError.address_of_carer}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldPets
                        multiline
                        id="standard-textarea"
                        label="Kind Of Pet"
                        value={PetsStateData.kind_of_pet}
                        name="kind_of_pet"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id="kind_of_pet"
                      />
                      <Box className={classes.errorMessage}>
                        {PetsDataError.kind_of_pet !== "" && <div className={classes.errorTextStyle}>{PetsDataError.kind_of_pet}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldPets
                        multiline
                        id="standard-textarea"
                        label="Name Of Pet"
                        value={PetsStateData.name_of_pet}
                        name="name_of_pet"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyle}
                        data-test-id="name_of_pet"
                      />
                      <Box className={classes.errorMessage}>
                        {PetsDataError.name_of_pet !== "" && <div className={classes.errorTextStyle}>{PetsDataError.name_of_pet}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.headingStyle}>
                        Shall any pets be cared for by RSPCA's home for life scheme if unable to be homed?
                      </Typography>
                      <FormControl variant="filled" className={classes.selectFieldRight}>
                        <StyledSelectFieldPets
                          disableUnderline
                          renderValue={(selected: any) => {
                            return selected;
                          }}
                          value={PetsStateData.any_pet_care_by_rspca_home}
                          name="any_pet_care_by_rspca_home"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                          data-test-id="any_pet_care_by_rspca_home">
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldPets>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {PetsDataError.any_pet_care_by_rspca_home !== "" && <div className={classes.errorTextStyle}>{PetsDataError.any_pet_care_by_rspca_home}</div>}
                      </Box>
                    </Grid>
                  </>
                }
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headingStyle}>
                    {configJSON.leaveMoneyGiftQuestion}
                  </Typography>
                  <FormControl variant="filled" className={classes.selectFieldRight}>
                    <StyledSelectFieldPets 
                      disableUnderline
                      renderValue={(selected: any) => {
                        return selected;
                      }}
                      value={PetsStateData.leave_gift_of_money_for_pet}
                      name="leave_gift_of_money_for_pet"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                      data-test-id="leave_gift_of_money_for_pet">
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                      <MenuItem value={"No"}>No</MenuItem>
                    </StyledSelectFieldPets>
                  </FormControl>
                  <Box className={classes.errorMessage}>
                    {PetsDataError.leave_gift_of_money_for_pet !== "" && <div className={classes.errorTextStyle}>{PetsDataError.leave_gift_of_money_for_pet}</div>}
                  </Box>
                </Grid>
                {PetsStateData.leave_gift_of_money_for_pet === "Yes" &&
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <StyledTextFieldPets
                      multiline
                      id="standard-textarea"
                      label="How much money would you like to leave with the your pets?"
                      value={PetsStateData.define_money_leave_for_pet}
                      name="define_money_leave_for_pet"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { PetsDataHandler?.(e) }}
                      type="text"
                      InputProps={{ disableUnderline: true }}
                      InputLabelProps={{
                        style: { color: "rgba(0, 0, 0, 0.54)" },
                      }}
                      className={classes.ageTextStyle}
                      data-test-id="define_money_leave_for_pet"
                    />
                    <Box className={classes.errorMessage}>
                      {PetsDataError.define_money_leave_for_pet !== "" && <div className={classes.errorTextStyle}>{PetsDataError.define_money_leave_for_pet}</div>}
                    </Box>
                  </Grid>
                }
                {
                  NextPreBtns({
                    PropValues:this.props,
                    nextBtnHandler:nextBtnHandler,
                    prevPage:"ExemptionsToYourWill",
                    nextPage:"ResidueEstate", 
                    nextErrorBtnHandler:nextErrorBtnHandlerPets
                  })
                }
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_pets = (theme: any) => ({

  fullWidthTextField: {
    width: "98%",
    margin: "8px",
    marginTop: "24px",
  },
  selectFieldRight: {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  headingStyle: {
    padding: "2% 0",
    color: "#000 !important"
  },
  personHeading: {
    padding: "10px 10px 5px 10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  textCustomStyle: {
    border: "1px solid #D3D3D3",
    padding: "6px"
  },
  ageTextStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px",
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5%"
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }

});

export const StyledTextFieldPets: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px !important",
    border: "1px solid black",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldPets: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);

export default Pets;
// Customizable Area End
