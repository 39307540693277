import React from "react";

import {
    Container,
    Button,
    Typography,
    // Customizable Area Start
    Box
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DropdownView,MaritalStatus } from "../../../components/src/CommonCustomForms";



export const mirror_theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import CustomForm2Controller, {
    Props,
} from "./CustomForm2Controller";

export class PersonalInfoMirrorWill extends CustomForm2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    onPerson1DateChange = (date: any) => {
        // const bdate = new Date(date).toString();
        this.userBirthDateHandler(date)
    }
    onPerson2DateChange = (date: any) => {
        // const bdate = new Date(date).toString();
        this.userBirthDatePerson2Handler(date)
    }

    formatInputValue = (value: string) => {
        if (value.length === 2 || value.length === 5) {
            return value + "/";
        }
        return value;
    };
   
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, nextBtnHandler,nextErrorBtnHandlerPersonMirror, userInputHandler, PersonalInfoStateData, PersonalInfoDataError } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={mirror_theme}>
                <Container maxWidth={"md"}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography className={classes.headLine}>
                                        Person1
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        multiline
                                        id="standard-textarea"
                                        label="Name"
                                        value={PersonalInfoStateData.person1_name}
                                        name="person1_name"
                                        type="text"
                                        InputProps={{ disableUnderline: true, }}
                                        className={classes.textCustomStyle}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person1_name"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person1_name !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_name}</div>}
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        label="Enter Birth Date"
                                        value={PersonalInfoStateData.person1_age}
                                        onBlur={() => {
                                            // Remove trailing slash before updating the state
                                            this.onPerson1DateChange(PersonalInfoStateData.person1_age.replace(/\//g, ""));
                                            // Validate and format the input value
                                            if (PersonalInfoStateData.person1_age.length === 8) {
                                                const formattedDate = PersonalInfoStateData.person1_age.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                                                this.onPerson1DateChange(formattedDate);
                                            }
                                        }}
                                        InputProps={{
                                            maxLength: 10,
                                            pattern: "\\d{2}/\\d{2}/\\d{4}", // Input pattern for validation
                                            title: "Enter a valid date in MM/DD/YYYY format", // Error message for invalid input
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                        id="standard-textarea"
                                        name="person1_age"
                                        type="date"
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person1_age"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person1_age !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_age}</div>}
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <StyledTextFieldMirror
                                        multiline
                                        id="standard-textarea"
                                        label="Address"
                                        value={PersonalInfoStateData.person1_address}
                                        name="person1_address"
                                        type="text"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person1_address"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person1_address !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_address}</div>}
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid container className={classes.PhoneNumberGrid}>
                                        <Grid item lg={8} md={8} sm={8} xs={8}>
                                            <StyledTextFieldMirror
                                                multiline
                                                id="standard-textarea"
                                                label="Phone Number"
                                                value={PersonalInfoStateData.person1_phone_number}
                                                name="person1_phone_number"
                                                type="text"
                                                InputProps={{ disableUnderline: true }}
                                                InputLabelProps={{
                                                    style: { color: "rgba(0, 0, 0, 0.54)" },
                                                }}
                                                className={classes.textCustomStyleMobile}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                                data-test-id="person1_phone_number"
                                            />
                                            <Box className={classes.errorMessage}>
                                                {PersonalInfoDataError.person1_phone_number !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_phone_number}</div>}
                                            </Box>
                                        </Grid>
                                        {
                                            DropdownView({
                                                userInputHandler:userInputHandler,
                                                countryCode:PersonalInfoStateData.person1_country_code,
                                                country_code_name:"person1_country_code",
                                                PropValues:this.props,
                                                StateValue:this.state.CountryList
                                            })
                                        }
                                    </Grid>

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        fullWidth
                                        id="standard-textarea"
                                        label="Email"
                                        value={PersonalInfoStateData.person1_email}
                                        name="person1_email"
                                        type="text"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person1_email"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person1_email !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_email}</div>}
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2} className={classes.styleContainer}>
                                        {
                                            MaritalStatus({
                                                PropValues:this.props,
                                                userInputHandler:userInputHandler,
                                                PersonalInfoDataError:PersonalInfoDataError.person1_marital_status,
                                                stateValue:PersonalInfoStateData.person1_marital_status,
                                                name:"person1_marital_status"
                                            })
                                        }
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Typography className={classes.headlineColor}>
                                                Can you confirm all your assets are in England and Wales?
                                            </Typography>
                                            <FormControl variant="filled" className={classes.formControlCustomStyle}>
                                                <StyledSelectFieldMirror 
                                                    disableUnderline
                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                        return selected;
                                                    }}
                                                    value={PersonalInfoStateData.person1_confirm_assets}
                                                    name="person1_confirm_assets"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                                    data-test-id="person1_confirm_assets"
                                                    >
                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                    <MenuItem value={"No"}>No</MenuItem>
                                                </StyledSelectFieldMirror>
                                            </FormControl>
                                            <Box className={classes.errorMessage}>
                                                {PersonalInfoDataError.person1_confirm_assets !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person1_confirm_assets}</div>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <hr style={{ opacity: 0.1 }} />
                                </Grid>


                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                        className={classes.headLine}>
                                        Person2
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        multiline
                                        id="standard-textarea"
                                        label="Name"
                                        value={PersonalInfoStateData.person2_name}
                                        name="person2_name"
                                        type="text"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person2_name"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person2_name !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_name}</div>}
                                    </Box>
                                </Grid>

                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        label="Enter Birth Date"
                                        value={PersonalInfoStateData.person2_age}
                                        onBlur={() => {
                                            // Remove trailing slash before updating the state
                                            this.onPerson2DateChange(PersonalInfoStateData.person2_age.replace(/\//g, ""));
                                            // Validate and format the input value
                                            if (PersonalInfoStateData.person2_age.length === 8) {
                                                const formattedDate = PersonalInfoStateData.person2_age.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                                                this.onPerson2DateChange(formattedDate);
                                            }
                                        }}
                                        InputProps={{
                                            maxLength: 10,
                                            pattern: "\\d{2}/\\d{2}/\\d{4}", // Input pattern for validation
                                            title: "Enter a valid date in MM/DD/YYYY format", // Error message for invalid input
                                            disableUnderline: true,
                                        }}
                                        fullWidth
                                        id="standard-textarea"
                                        name="person2_age"
                                        type="date"
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person2_age"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person2_age !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_age}</div>}
                                    </Box>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <StyledTextFieldMirror
                                        multiline
                                        id="standard-textarea"
                                        label="Address"
                                        value={PersonalInfoStateData.person2_address}
                                        name="person2_address"
                                        type="text"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person2_address"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person2_address !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_address}</div>}
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Grid container className={classes.PhoneNumberGrid}>
                                        <Grid item lg={8} md={8} sm={8} xs={8}>
                                            <StyledTextFieldMirror
                                                multiline
                                                id="standard-textarea"
                                                label="Phone Number"
                                                value={PersonalInfoStateData.person2_phone_number}
                                                name="person2_phone_number"
                                                type="text"
                                                InputProps={{ disableUnderline: true }}
                                                InputLabelProps={{
                                                    style: { color: "rgba(0, 0, 0, 0.54)" },
                                                }}
                                                className={classes.textCustomStyleMobile}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                                data-test-id="person2_phone_number"
                                            />
                                            <Box className={classes.errorMessage}>
                                                {PersonalInfoDataError.person2_phone_number !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_phone_number}</div>}
                                            </Box>
                                        </Grid>
                                        {
                                            DropdownView({
                                                userInputHandler:userInputHandler,
                                                countryCode:PersonalInfoStateData.person2_country_code,
                                                country_code_name:"person2_country_code",
                                                PropValues:this.props,
                                                StateValue:this.state.CountryList
                                            })
                                        }
                                    </Grid>

                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <StyledTextFieldMirror
                                        fullWidth
                                        id="standard-textarea"
                                        label="Email"
                                        value={PersonalInfoStateData.person2_email}
                                        name="person2_email"
                                        type="text"
                                        InputProps={{ disableUnderline: true }}
                                        InputLabelProps={{
                                            style: { color: "rgba(0, 0, 0, 0.54)" },
                                        }}
                                        className={classes.textCustomStyle}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                        data-test-id="person2_email"
                                    />
                                    <Box className={classes.errorMessage}>
                                        {PersonalInfoDataError.person2_email !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_email}</div>}
                                    </Box>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container spacing={2}
                                        className={classes.styleContainer}>
                                        {
                                          MaritalStatus({
                                            PropValues:this.props,
                                            userInputHandler:userInputHandler,
                                            PersonalInfoDataError:PersonalInfoDataError.person2_marital_status,
                                            stateValue:PersonalInfoStateData.person2_marital_status,
                                            name:"person2_marital_status"
                                          })  
                                        }

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Typography className={classes.headlineColor}>
                                                Can you confirm all your assets are in England and Wales?
                                            </Typography>
                                            <FormControl variant="filled" className={classes.formControlCustomStyle}>
                                                <StyledSelectFieldMirror 
                                                    disableUnderline
                                                    renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                                                        return selected;
                                                    }}
                                                    value={PersonalInfoStateData.person2_confirm_assets}
                                                    name="person2_confirm_assets"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandler?.(e) }}
                                                    data-test-id="person2_confirm_assets"
                                                    >
                                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                                    <MenuItem value={"No"}>No</MenuItem>
                                                </StyledSelectFieldMirror>
                                            </FormControl>
                                            <Box className={classes.errorMessage}>
                                                {PersonalInfoDataError.person2_confirm_assets !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataError.person2_confirm_assets}</div>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.nextBtnContainer}>
                                <Button
                                    type="button"
                                    className={classes.nextBtn}
                                    style={{
                                        textTransform: "none",
                                    }}
                                    disabled={nextErrorBtnHandlerPersonMirror?.(this.props)}
                                    onClick={() => { nextBtnHandler?.("Executors") }}
                                    data-test-id="next-Btn"
                                    >
                                    Next
                                </Button>
                            </Grid>
                        </form>
                    </Grid>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const useStyle_Mirror = (mirror_theme: any) => ({

    textCustomStyle: {
        border: "1px solid #D3D3D3",
        padding: "10px"
    },
    dateCustomStyle: {
        border: "1px solid #D3D3D3",
        padding: "10px",
        '& button': {
            color: "#fff !important"
        }
    },
    textCustomStyleMobile: {
        border: "1px solid #D3D3D3",
        padding: "10px",
        borderRadius: "8px 0px 0px 8px !important"
    },
    headLine: {
        padding: "10px",
        color: "rgba(0, 0, 0, 0.54)"
    },
    nextBtnContainer: {
        marginTop: "5%",
        display: "flex",
        justifyContent: "flex-end"
    },
    nextBtn: {
        backgroundColor: "#364F6B",
        color: "#ffffff",
        width: "20%",
        padding: "10px",
        margin: "8px 8px 26px 8px",
    },
    formControlCustomStyleMobileSingle: {
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        "& div": {
          borderRadius: "0px 8px 8px 0px !important",
          backgroundColor: "#ffffff !important",
        },
        "& div input": {
          padding: "10px 10px"
        },
        "& div:focus": {
          borderRadius: "8px",
          backgroundColor: "#ffffff !important",
        },
        "& .MuiFilledInput": {
          padding: "27px 12px 1px !important"
        }
      },
    formControlCustomStyle: {
        display: "flex",
        marginTop: "10px !important",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        "& div": {
            borderRadius: "8px",
            backgroundColor: "#ffffff !important",
        },
        "& div:focus": {
            borderRadius: "8px",
            backgroundColor: "#ffffff !important",
        }
    },
    styleContainer: {
        alignItems: "flex-end"
    },
    headlineColor: {
        color: "#000 !important",
        paddingBottom: "10px"
    },
    countryCodeContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: "5px"
    },
    countryFlag: {
        borderRadius: "50%",
        height: "25px",
        width: "30px",
        marginRight: "5px"
    },
    PhoneNumberGrid: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    errorTextStyle: {
        color: "red",
        fontSize: "0.9rem"
    },
    errorMessage: {
        height: "1rem",
    }
});

export const StyledTextFieldMirror: any = withStyles({
    root: {
        fontSize: "14",
        borderRadius: "8px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#ffffff !important",
        display: "block !important",
        "& #standard-textarea-label": {
            padding: "0px 0px 0px 10px",
        },
        "& .Mui-focused": {
            padding: "10px 0px 0px 10px !important",
        },
        "& #standard-textarea": {
            color: "#000 !important"
        }

    },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldMirror:any = withStyles({
    root: {
        border: "1px solid #D3D3D3 !important",
        borderRadius: "8px",
        backgroundColor: "#ffffff !important",
        height: "auto",
        padding: "21.5px 10px !important"
    }
})((props: any) => <Select {...props} />);

export default PersonalInfoMirrorWill;

// Customizable Area End
