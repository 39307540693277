import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { NextPreBtns } from "../../../components/src/CommonCustomForms";



export const a_theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";


export class AppointingGuardian extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler, nextErrorBtnHandlerAppointing, AppointingGuardianStateData, AppointingGuardianHandler, AppointingGuardianDataError } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={a_theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} className={classes.styleContainer}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.headlineColor}>
                        Do you have any children under the age of 18?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyle}>
                        <StyledSelectFieldAppoint
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={AppointingGuardianStateData.define_children_under_age_of_18}
                          name="define_children_under_age_of_18"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                          data-test-id="define_children_under_age_of_18">
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldAppoint>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {AppointingGuardianDataError.define_children_under_age_of_18 !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.define_children_under_age_of_18}</div>}
                      </Box>
                    </Grid>
                    {AppointingGuardianStateData.define_children_under_age_of_18 === "Yes" &&
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className={classes.headlineColor}>
                          Would you like to appoint a guardian
                        </Typography>
                        <FormControl variant="filled" className={classes.formControlCustomStyle}>
                          <StyledSelectFieldAppoint
                            disableUnderline
                            renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                              return selected;
                            }}
                            value={AppointingGuardianStateData.appoint_guardian}
                            name="appoint_guardian"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                            data-test-id="appoint_guardian">
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                          </StyledSelectFieldAppoint>
                        </FormControl>
                        <Box className={classes.errorMessage}>
                          {AppointingGuardianDataError.appoint_guardian !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.appoint_guardian}</div>}
                        </Box>
                      </Grid>
                    }
                    {AppointingGuardianStateData.appoint_guardian === "Yes" ?
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Grid container spacing={2} className={classes.styleContainer}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <StyledTextFieldAppoint
                                multiline
                                id="standard-textarea"
                                label="Name of child"
                                value={AppointingGuardianStateData.child1_name}
                                name="child1_name"
                                type="text"
                                InputProps={{ disableUnderline: true, className: classes.input }}
                                InputLabelProps={{
                                  style: { color: "rgba(0, 0, 0, 0.54)" },
                                }}
                                className={classes.textCustomStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                                data-test-id="child1_name"
                              />
                              <Box className={classes.errorMessage}>
                                {AppointingGuardianDataError.child1_name !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.child1_name}</div>}
                              </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <StyledTextFieldAppoint
                                multiline
                                id="standard-textarea"
                                label="Address of child"
                                value={AppointingGuardianStateData.child1_address}
                                name="child1_address"
                                type="text"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{
                                  style: { color: "rgba(0, 0, 0, 0.54)" },
                                }}
                                className={classes.textCustomStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                                data-test-id="child1_address"
                              />
                              <Box className={classes.errorMessage}>
                                {AppointingGuardianDataError.child1_address !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.child1_address}</div>}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className={classes.styleContainer}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <StyledTextFieldAppoint
                                multiline
                                id="standard-textarea"
                                label="Age of child"
                                value={AppointingGuardianStateData.child1_age}
                                name="child1_age"
                                type="text"
                                InputProps={{ disableUnderline: true, className: classes.input }}
                                InputLabelProps={{
                                  style: { color: "rgba(0, 0, 0, 0.54)" },
                                }}
                                className={classes.textCustomStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                                data-test-id="child1_age"
                              />
                              <Box className={classes.errorMessage}>
                                {AppointingGuardianDataError.child1_age !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.child1_age}</div>}
                              </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <StyledTextFieldAppoint
                                multiline
                                id="standard-textarea"
                                label="Sex of child"
                                value={AppointingGuardianStateData.child1_sex}
                                name="child1_sex"
                                type="text"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{
                                  style: { color: "rgba(0, 0, 0, 0.54)" },
                                }}
                                className={classes.textCustomStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                                data-test-id="child1_sex"
                              />
                              <Box className={classes.errorMessage}>
                                {AppointingGuardianDataError.child1_sex !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.child1_sex}</div>}
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className={classes.styleContainer}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <StyledTextFieldAppoint
                                multiline
                                id="standard-textarea"
                                label="Do you require"
                                value={AppointingGuardianStateData.doyou_require}
                                name="doyou_require"
                                type="text"
                                InputProps={{ disableUnderline: true }}
                                InputLabelProps={{
                                  style: { color: "rgba(0, 0, 0, 0.54)" },
                                }}
                                className={classes.textCustomStyle}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                                data-test-id="doyou_require"
                              />
                              <Box className={classes.errorMessage}>
                                {AppointingGuardianDataError.doyou_require !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.doyou_require}</div>}
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography className={classes.headlineColor}>
                            Wishes for Guardians. i.e do you wish the child to be raised in a particular faith?
                          </Typography>
                          <StyledTextFieldAppoint
                            multiline
                            id="standard-textarea"
                            label="Wishes for Guardians"
                            value={AppointingGuardianStateData.wishes_for_guardian}
                            name="wishes_for_guardian"
                            type="text"
                            InputProps={{ disableUnderline: true, className: classes.input }}
                            InputLabelProps={{
                              style: { color: "rgba(0, 0, 0, 0.54)" },
                            }}
                            className={classes.textCustomStyle}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { AppointingGuardianHandler?.(e) }}
                            data-test-id="wishes_for_guardian"
                          />
                          <Typography>
                            <Box className={classes.errorMessage}>
                              {AppointingGuardianDataError.wishes_for_guardian !== "" && <div className={this.props.classes.errorTextStyle}>{AppointingGuardianDataError.wishes_for_guardian}</div>}
                            </Box>
                          </Typography>
                        </Grid>
                      </>
                      : null}

                  </Grid>
                </Grid>

                {
                  NextPreBtns({
                    PropValues: this.props,
                    nextBtnHandler: nextBtnHandler,
                    prevPage: "FuneralArrangments",
                    nextPage: "LeaveaGift",
                    nextErrorBtnHandler: nextErrorBtnHandlerAppointing
                  })
                }
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_appointing = (a_theme: any) => ({

  textCustomStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px"
  },
  headLine: {
    padding: "10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  formControlCustomStyle: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  styleContainer: {
    // alignItems: "flex-end"
    alignItems: "baseline",
    padding: "1rem 0rem"
  },
  headlineColor: {
    padding: "2% 0",
    color: "#000 !important",
    height: "4rem !important"
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }
});

export const StyledTextFieldAppoint: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px",
    border: "1px solid #D3D3D3",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldAppoint: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    // padding:"25px 10px !important"
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);

export default AppointingGuardian;
// Customizable Area End
