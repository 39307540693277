// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    TextField,
    Box,
    Grid
} from "@material-ui/core";
import { DropdownViewForList } from "./CommonCustomForms";

export const StyledTextFieldLeaveGift: any = withStyles({
    root: {
        fontSize: "14",
        borderRadius: "8px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#ffffff !important",
        display: "block !important",
        "& #standard-textarea-label": {
            padding: "0px 0px 0px 10px",
        },
        "& .Mui-focused": {
            padding: "10px 0px 0px 10px !important",
        },
        "& #standard-textarea": {
            color: "#000 !important"
        }

    },
})((props: any) => <TextField {...props} />);

const CustomPhoneNumField = (props: any) => {
    const {
        PhoneNumberGridStyle,        
        value,
        name,
        index,
        textFieldStyle,        
        onChangeHandler,
        dataTestId,
        errorMsg,
        errorMessageStyle,
        countryCodeInput,
        countryCodeNameInput,
        propsValue,
        CountryList
     } = props;
    return (
        <Grid item lg={6} md={6} sm={6} xs={12}>
            <Grid container className={PhoneNumberGridStyle}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                    <StyledTextFieldLeaveGift
                        multiline
                        id="standard-textarea"
                        label="Phone Number"
                        value={value}
                        name={name}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                            style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={textFieldStyle}
                        style={{
                            borderRadius: "8px 0px 0px 8px !important"
                        }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { onChangeHandler?.(event, index) }}
                        data-test-id={dataTestId}
                    />
                    <Box className={errorMessageStyle}>
                        {errorMsg}
                    </Box>
                </Grid>
                {
                    DropdownViewForList({
                        userInputHandlerList: onChangeHandler,
                        countryCode: countryCodeInput,
                        country_code_name: countryCodeNameInput,
                        PropValues: propsValue,
                        StateValue: CountryList,
                        index: index
                    })
                }
            </Grid>
        </Grid>
    )
}

export default CustomPhoneNumField;

// Customizable Area End