import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DropdownView } from "../../../components/src/CommonCustomForms";



export const single_theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End

import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";

export class PersonalInfoSingleWill extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onSingleUserDateChange = (date: any) => {
    // const bdate = new Date(date).toString();
    this.singleUserBirthDatePerson2Handler(date)
  }

  formatInputValue = (value: string) => {
    if (value.length === 2 || value.length === 5) {
      return value + "/";
    }
    return value;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerPersonalSingle, PersonalInfoDataStateSingle, userInputHandlerSingle, PersonalInfoDataSingleError }:any = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={single_theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <StyledTextFieldSingle
                    multiline
                    id="standard-textarea"
                    label="Name"
                    value={PersonalInfoDataStateSingle.person_name}
                    name="person_name"
                    type="text"
                    InputProps={{ disableUnderline: true, }}
                    className={classes.textCustomStyle}
                    InputLabelProps={{
                      style: { color: "rgba(0, 0, 0, 0.54)" },
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(event) }}
                    data-test-id="person_name_id"
                  />
                  <div className={classes.errorMessage}>
                    {PersonalInfoDataSingleError.person_name !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_name}</div>}
                  </div>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <StyledTextFieldSingle
                    label="Enter Birth Date"
                    value={PersonalInfoDataStateSingle.person_age}
                    onBlur={() => {
                      // Remove trailing slash before updating the state
                      this.onSingleUserDateChange(PersonalInfoDataStateSingle.person_age.replace(/\//g, ""));
                      // Validate and format the input value
                      if (PersonalInfoDataStateSingle.person_age.length === 8) {
                        const formattedDate = PersonalInfoDataStateSingle.person_age.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
                        this.onSingleUserDateChange(formattedDate);
                      }
                    }}
                    InputProps={{
                      maxLength: 10,
                      pattern: "\\d{2}/\\d{2}/\\d{4}", // Input pattern for validation
                      title: "Enter a valid date in MM/DD/YYYY format", // Error message for invalid input
                      disableUnderline: true,
                    }}
                    fullWidth
                    id="standard-textarea"
                    name="person_age"
                    type="date"
                    InputLabelProps={{
                      style: { color: "rgba(0, 0, 0, 0.54)" },
                    }}
                    className={classes.textCustomStyle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                    data-test-id="person_age"
                  />
                  <div className={classes.errorMessage}>
                    {PersonalInfoDataSingleError.person_age !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_age}</div>}
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <StyledTextFieldSingle
                    multiline
                    id="standard-textarea"
                    label="Address"
                    value={PersonalInfoDataStateSingle.person_address}
                    name="person_address"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      style: { color: "rgba(0, 0, 0, 0.54)" },
                    }}
                    className={classes.textCustomStyle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                    data-test-id="person_address"
                  />
                  <div className={classes.errorMessage}>
                    {PersonalInfoDataSingleError.person_address !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_address}</div>}
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid container className={classes.PhoneNumberGrid}>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <StyledTextFieldSingle
                        multiline
                        id="standard-textarea"
                        label="Phone Number"
                        value={PersonalInfoDataStateSingle.person_phone_number}
                        name="person_phone_number"
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        className={classes.textCustomStyleMobile}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                        data-test-id="person_phone_number"
                      />
                      <div className={classes.errorMessage}>
                        {PersonalInfoDataSingleError.person_phone_number !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_phone_number}</div>}
                      </div>
                    </Grid>
                    {
                      DropdownView({
                        userInputHandler:userInputHandlerSingle,
                        countryCode:PersonalInfoDataStateSingle.country_code,
                        country_code_name:"country_code",
                        PropValues:this.props,
                        StateValue:this.state.CountryList
                      })
                    }
                  </Grid>

                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <StyledTextFieldSingle
                    fullWidth
                    id="standard-textarea"
                    label="Email"
                    value={PersonalInfoDataStateSingle.person_email}
                    name="person_email"
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      style: { color: "rgba(0, 0, 0, 0.54)" },
                    }}
                    className={classes.textCustomStyle}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                    data-test-id="person_email"
                  />
                  <div className={classes.errorMessage}>
                    {PersonalInfoDataSingleError.person_email !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_email}</div>}
                  </div>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} className={classes.styleContainerSingle}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.headlineColorSingle}>
                        What is your marital status?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleSingle}>
                        <StyledSelectField disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={PersonalInfoDataStateSingle.person_marital_status}
                          name="person_marital_status"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                          data-test-id="person_marital_status"
                        >
                          <MenuItem value={"Civil Partnership"}>Civil Partnership</MenuItem>
                          <MenuItem value={"Single"}>Single</MenuItem>
                          <MenuItem value={"Married"}>Married</MenuItem>
                          <MenuItem value={"Widowed"}>Widowed</MenuItem>
                          <MenuItem value={"Divorced"}>Divorced</MenuItem>
                          <MenuItem value={"Cohabiting"}>Cohabiting</MenuItem>
                        </StyledSelectField>
                      </FormControl>
                      <div className={classes.errorMessage}>
                        {PersonalInfoDataSingleError.person_marital_status !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_marital_status}</div>}
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className={classes.headlineColorSingle}>
                        Can you confirm all your assets are in England and Wales?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleSingle}>
                        <StyledSelectField disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={PersonalInfoDataStateSingle.person_confirm_assets}
                          name="person_confirm_assets"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerSingle(e) }}
                          data-test-id="person_confirm_assets"
                          >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectField>
                      </FormControl>
                      <div className={classes.errorMessage}>
                        {PersonalInfoDataSingleError.person_confirm_assets !== "" && <div className={classes.errorTextStyle}>{PersonalInfoDataSingleError.person_confirm_assets}</div>}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.nextBtnContainer}>
                  <Button
                    className={classes.nextBtn}
                    style={{textTransform: "none"}}
                    disabled={nextErrorBtnHandlerPersonalSingle(this.props)}
                    onClick={() => { nextBtnHandler("Executors") }}
                    data-test-id="next-Btn"
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_singleWill = (single_theme: any) => ({

  textCustomStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px"
  },
  textCustomStyleMobile: {
    border: "1px solid #D3D3D3",
    padding: "10px",
    borderRadius: "8px 0px 0px 8px !important"
  },
  headLine: {
    padding: "10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  nextBtnContainer: {
    marginTop: "5%",
    display: "flex",
    justifyContent: "flex-end"
  },
  nextBtn: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  formControlCustomStyleMobileSingle: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "0px 8px 8px 0px !important",
      backgroundColor: "#ffffff !important",
    },
    "& div input": {
      padding: "10px 10px"
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& .MuiFilledInput": {
      padding: "27px 12px 1px !important"
    }
  },
  formControlCustomStyleSingle: {
    display: "flex",
    backgroundColor: "#ffffff !important",
    borderRadius: "8px",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  styleContainerSingle: {
    // alignItems: "flex-end"
    alignItems: "baseline"
  },
  headlineColorSingle: {
    color: "#000 !important",
    padding: "4% 0 !important",
    height: "5rem !important"
  },
  countryCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "5px"
  },
  countryFlag: {
    borderRadius: "50%",
    height: "25px",
    width: "30px",
    marginRight: "5px"
  },
  PhoneNumberGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }
});

export const StyledTextFieldSingle: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px",
    border: "1px solid #D3D3D3",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField InputLabelProps={{ shrink: true }} {...props} />);

export const StyledSelectField: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"

  }
})((props: any) => <Select {...props} />);

export default PersonalInfoSingleWill;

// Customizable Area End
