import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomForm2Controller, {
  configJSON,
  Props,
} from "./CustomForm2Controller";
import {DropdownViewForList, NextPreBtns} from "../../../components/src/CommonCustomForms";



export const e_theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End



export class Executors extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerExecutors, ExecutorsStateData, userInputHandlerExecutor, ExecutorsDataError,addExecutorsHandler,removeExecutorsHandler,userInputHandlerExecutorList } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={e_theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.headingStyle}>
                    Who would you like to be your Executors
                  </Typography>
                </Grid>  
                {
                  ExecutorsStateData.executorsList?.map((item:{[key:string]:string},index:number)=>{
                    return(
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12}
                      className={classes.removeGridContainer}>
                        <Box className={classes.removeContainer}>
                          <Typography
                            className={classes.personHeading}>
                            Person {1+index}
                          </Typography>
                          {ExecutorsStateData.executorsList.length!==1 &&
                          <Button
                          className={classes.removeBtn}
                          style={{ 
                            textTransform: "none",
                            float: "inline-end",
                            fontWeight: "initial",}}
                          type="button"
                          onClick={()=>{removeExecutorsHandler?.(index)}}>
                            Remove
                          </Button>
                         }
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <StyledTextFieldExecutor
                          multiline
                          id="standard-textarea"
                          label="Name"
                          value={item['executor_person'+(index+1)+'_name']}
                          name={`executor_person${1+index}_name`}
                          type="text"
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            style: { color: "rgba(0, 0, 0, 0.54)" },
                          }}
                          className={classes.textCustomStyle}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutorList?.(event,index) }}
                          data-test-id={`executor_person${1+index}_name`}
                        />
                        <Box className={classes.errorMessage}>
                          {ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_name'] !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_name']}</div>}
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <StyledTextFieldExecutor
                          multiline
                          id="standard-textarea"
                          label="Address"
                          value={item['executor_person'+(index+1)+'_address']}
                          name={`executor_person${index+1}_address`}
                          type="text"
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            style: { color: "rgba(0, 0, 0, 0.54)" },
                          }}
                          className={classes.textCustomStyle}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutorList?.(event,index) }}
                          data-test-id={`executor_person${index+1}_address`}
                        />
                        <Box className={classes.errorMessage}>
                          {ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_address'] !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_address']}</div>}
                        </Box>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Grid container className={classes.PhoneNumberGrid}>
                          <Grid item lg={8} md={8} sm={8} xs={8}>
                            <StyledTextFieldExecutor
                              multiline
                              id="standard-textarea"
                              label="Phone Number"
                              value={item['executor_person'+(index+1)+'_phone_number']}
                              name={`executor_person${index+1}_phone_number`}
                              type="text"
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={{
                                style: { color: "rgba(0, 0, 0, 0.54)" },
                              }}
                              className={classes.textCustomStyleMobile}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutorList?.(event,index) }}
                              data-test-id={`executor_person${index+1}_phone_number`}
                            />
                            <Box className={classes.errorMessage}>
                              {ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_phone_number'] !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_phone_number']}</div>}
                            </Box>
                          </Grid>
                          {
                            DropdownViewForList({
                              userInputHandlerList:userInputHandlerExecutorList,
                              countryCode:item['executor_person'+(index+1)+'_country_code'],
                              country_code_name:"executor_person"+(index+1)+"_country_code",
                              PropValues:this.props,
                              StateValue:this.state.CountryList,
                              index:index
                            })
                          }
                        </Grid>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <StyledTextFieldExecutor
                          multiline
                          id="standard-textarea"
                          label="Relation to you"
                          value={item['executor_person'+(index+1)+'_relation']}
                          name={`executor_person${index+1}_relation`}
                          type="text"
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            style: { color: "rgba(0, 0, 0, 0.54)" },
                          }}
                          className={classes.textCustomStyle}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutorList?.(event,index) }}
                          data-test-id={`executor_person${index+1}_relation`}
                        />
                        <Box className={classes.errorMessage}>
                          {ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_relation'] !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executorsList[index]['executor_person'+(index+1)+'_relation']}</div>}
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}
                        className={classes.addBtnContainer}>
                        <Button 
                        className={classes.addBtn}
                        style={{fontWeight: "initial",textTransform: "none",}}
                        onClick={()=>{addExecutorsHandler?.()}} 
                        disabled={ExecutorsStateData.executorsList.length>=4?true:false}
                        >
                          +Add
                        </Button> 
                      </Grid> 
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <hr style={{ opacity: 0.1 }} />
                      </Grid>
                    </>
                    )
                  })
                }
                <Box className={classes.errorMessage}>
                    {ExecutorsStateData.executorsList?.length>=4 && <div className={classes.errorTextStyle}>{configJSON.limitErrorMessage}</div>}
                </Box>
                
                <Box>
                  <Box className={classes.fullWidthTextField}>
                    <Typography className={classes.headingStyle}>
                      Shall there be different Trustees appointed to the Executors?
                    </Typography>

                    <FormControl variant="filled" className={classes.selectFieldRight}>
                      <StyledSelectFieldExecutor 
                        disableUnderline
                        renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                          return selected;
                        }}
                        value={ExecutorsStateData.executor_diff_trustees}
                        name="executor_diff_trustees"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutor?.(e) }}
                        data-test-id="executor_diff_trustees"
                        >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </StyledSelectFieldExecutor>
                    </FormControl>
                    <Box className={classes.errorMessage}>
                      {ExecutorsDataError.executor_diff_trustees !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executor_diff_trustees}</div>}
                    </Box>
                  </Box>
                  <Box
                    className={classes.fullWidthTextField}>
                    <Typography className={classes.headingStyle}>
                      Would you like to include a cluase that ensures an executor act when they reach a certain age(please note you have to be over the age of 18 to be executor)
                    </Typography>
                    <FormControl variant="filled" className={classes.selectFieldRight}>
                      <StyledSelectFieldExecutor 
                        disableUnderline
                        renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                          return selected;
                        }}
                        value={ExecutorsStateData.executor_reach_certain_age}
                        name="executor_reach_certain_age"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutor?.(e) }}
                        data-test-id="executor_reach_certain_age"
                        >
                        <MenuItem value={"25"}>25</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </StyledSelectFieldExecutor>
                    </FormControl>
                    <Box className={classes.errorMessage}>
                      {ExecutorsDataError.executor_reach_certain_age !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executor_reach_certain_age}</div>}
                    </Box>
                  </Box>
                  {ExecutorsStateData.executor_reach_certain_age === "Other" && 
                  <>
                      <StyledTextFieldExecutor
                      multiline
                      id="standard-textarea"
                      label="Enter Age"
                      value={ExecutorsStateData.executor_enter_age}
                      name="executor_enter_age"
                      type="text"
                      InputProps={{ disableUnderline: true }}
                      InputLabelProps={{
                        style: { color: "rgba(0, 0, 0, 0.54)" },
                      }}
                      className={classes.ageTextStyle}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => { userInputHandlerExecutor?.(e) }}
                      data-test-id="executor_enter_age"
                      />
                      <Box className={classes.errorMessage} sx={{ ml: "0.5rem !important" }}>
                        {ExecutorsDataError.executor_enter_age !== "" && <div className={classes.errorTextStyle}>{ExecutorsDataError.executor_enter_age}</div>}
                      </Box>
                  </>
                  }
                </Box>
                {
                  NextPreBtns({
                    PropValues:this.props,
                    nextBtnHandler:nextBtnHandler,
                    prevPage:"PersonalInfo1",
                    nextPage:"FuneralArrangments",
                    nextErrorBtnHandler:nextErrorBtnHandlerExecutors
                  })
                }
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle = (theme: any) => ({

  fullWidthTextField: {
    width: "98%",
    margin: "8px",
    marginTop: "24px",
  },
  selectFieldRight: {
    width: "100%",
    margin: "8px 0px 8px 0px",
    "& div": {
      borderRadius: "8px",
    },
    "& div:focus": {
      borderRadius: "8px"
    }
  },
  headingStyle: {
    padding: "2% 0",
    color: "#000 !important"
  },
  personHeading: {
    padding: "10px 10px 5px 10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  textCustomStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px"
  },
  textCustomStyleMobile: {
    border: "1px solid #D3D3D3",
    padding: "10px",
    borderRadius: "8px 0px 0px 8px !important"
  },
  ageTextStyle: {
    border: "1px solid #D3D3D3",
    padding: "10px",
    width: "98%",
    margin: "8px"
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5%"
  },
  addBtnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  addBtn:{
    fontSize: "medium",
    color: "#000 !important",
  },
  removeGridContainer:{
    padding: "8px 8px 0px 8px !important"
  },
  removeContainer:{
    display: "flex",
    justifyContent: "space-between"
  },
  removeBtn:{
    fontSize: "medium",
    color: "red"
  },
  formControlCustomStyleMobileSingle: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "0px 8px 8px 0px !important",
      backgroundColor: "#ffffff !important",
    },
    "& div input": {
      padding: "10px 10px"
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& .MuiFilledInput": {
      padding: "27px 12px 1px !important"
    }
  },
  countryCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "5px"
  },
  countryFlag: {
    borderRadius: "50%",
    height: "25px",
    width: "30px",
    marginRight: "5px"
  },
  PhoneNumberGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }

});

 export const StyledTextFieldExecutor: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px !important",
    border: "1px solid black",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldExecutor: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);

export default Executors;
// Customizable Area End
