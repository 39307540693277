import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, UKflag, EnglandFlag, NIRflag, ScotlandFlag, WalesFlag } from "./assets";
import { toast } from "react-toastify";
export const helperFun = require("./helperFunction");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  inheritanceStateValue?: any;
  inheritanceStateName?: any;
  stateValue?: any;
  name?: any;
  showErrorMessageHandler?: (item: string) => JSX.Element;
  transportStateValue?: any;
  transportStateName?: any;
  prevPage?: any;
  nextPage?: any;
  nextErrorBtnHandler?: (item: string) => void;
  countryCode?: any;
  country_code_name?: any;
  PropValues?: any;
  StateValue?: any;
  index?: number;
  nextBtnHandler?: (item: string) => void;
  nextErrorBtnHandlerPersonMirror?: (item: {}) => boolean;
  nextErrorBtnHandlerPersonalSingle?: (item: string) => void;
  nextErrorBtnHandlerExecutors?: (item: {}) => boolean;
  addExecutorsHandler?: () => void;
  removeExecutorsHandler?: (index: number) => void;
  addGiftsHandler?: () => void;
  addGiftMoneyHandler?: () => void;
  addRecipientHandler?: () => void;
  removeGiftsHandler?: (index: number) => void;
  removeGiftsMoneyHandler?: (index: number) => void;
  removeRecipientHandler?: (index: number) => void;
  removeProperty?: (index: number) => void;
  addProperty?: () => void;
  addResiduePersons?: () => void;
  removeResiduePersons?: (index: number) => void;
  nextErrorBtnHandlerFuneral?: (item: {}) => boolean;
  nextErrorBtnHandlerAppointing?: (item: {}) => boolean;
  nextErrorBtnHandlerLeaveGift?: (item: {}) => boolean;
  nextErrorBtnHandlerExemption?: (item: {}) => boolean;
  nextErrorBtnHandlerPets?: (item: {}) => boolean;
  nextErrorBtnHandlerResidue?: (item: {}) => boolean;
  classes: { [key: string]: string };
  history?: any;
  PersonalInfoStateData?: any;
  userInputHandler?: (item: React.ChangeEvent<HTMLInputElement>) => void;
  userInputHandlerList?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  PersonalInfoDataError?: any;
  ExecutorsStateData?: any;
  userInputHandlerExecutor?: (item: React.ChangeEvent<HTMLInputElement>) => void;
  userInputHandlerExecutorList?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  ExecutorsDataError?: any;
  FuneralArrangementStateData?: any;
  userInputHandlerFuneral?: (item: any) => void;
  FuneralArrangementDataError?: any;
  AppointingGuardianStateData?: any;
  AppointingGuardianHandler?: (item: any) => void;
  AppointingGuardianDataError?: any;
  LeaveGiftStateData?: any;
  LeaveGiftHandler?: (item: any) => void;
  LeaveGiftListHandler?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  LeaveGiftMoneyListHandler?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  LeaveRecipientListHandler?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  LeavePropertyListHandler?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  LeaveGiftDataErrorMessage?: string | any;
  LeaveGiftDataError?: any;
  ExemptionStateData?: any;
  ExemptionDataHandler?: (item: any) => void;
  ExemptionDataError?: any;
  PetsStateData?: any;
  PetsDataHandler?: (item: any) => void;
  PetsDataError?: any;
  ResidueEstateStateData?: any;
  ResidueDataHandler?: (item: any) => void;
  ResiduePersonsHandler?: (item: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  ResidueEstateDataError?: any;
  PersonalInfoDataStateSingle?: any;
  userInputHandlerSingle?: (item: React.ChangeEvent<HTMLInputElement>) => void;
  PersonalInfoDataSingleError?: any;
  createCustomFormApiCall?: () => void;
  handleCloseThankyouModal?: (item: any) => void;
  modalOpenState?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openThankyouModal: boolean;
  flag: boolean;
  formFlag: string;
  PersonalInfoData: {
    person1_name: string,
    person1_age: string,
    person1_address: string,
    person1_phone_number: string,
    person1_country_code: {
      name: string,
      code: string,
      path: string
    },
    person1_email: string,
    person1_marital_status: string,
    person1_confirm_assets: string,
    person2_name: string,
    person2_age: string,
    person2_address: string,
    person2_phone_number: string,
    person2_country_code: {
      name: string,
      code: string,
      path: string
    },
    person2_email: string,
    person2_marital_status: string,
    person2_confirm_assets: string
  };
  PersonalInfoDataError: {
    person1_name: string | null,
    person1_age: string | null,
    person1_address: string | null,
    person1_phone_number: string | null,
    person1_email: string | null,
    person1_marital_status: string | null,
    person1_confirm_assets: string | null,
    person2_name: string | null,
    person2_age: string | null,
    person2_address: string | null,
    person2_phone_number: string | null,
    person2_email: string | null,
    person2_marital_status: string | null,
    person2_confirm_assets: string | null
  };
  PersonalInfoDataSingle: {
    person_name: string,
    person_age: string,
    person_address: string,
    person_phone_number: string,
    country_code: {
      name: string,
      code: string,
      path: string
    },
    person_email: string,
    person_marital_status: string,
    person_confirm_assets: string,
  };
  PersonalInfoDataSingleError: {
    person_name: string | null,
    person_age: string | null,
    person_address: string | null,
    person_phone_number: string | null,
    person_email: string | null,
    person_marital_status: string | null,
    person_confirm_assets: string | null,
  };
  ExecutorsData: {
    executorsList: { [key: string]: string | {} }[],
    executor_diff_trustees: string,
    executor_reach_certain_age: string,
    executor_enter_age: string
  };
  ExecutorsDataError: {
    executorsList: { [key: string]: string | null }[],
    executor_diff_trustees: string | null,
    executor_reach_certain_age: string | null,
    executor_enter_age: string | null
  };
  FuneralArrangementData: {
    specify_funeral_arrangement: string,
    specify_buried_or_cremated: string,
    any_other_funeral_requirement: string,
    organs_donation: string,
    Yes_all_organs: string,
    specific_organs: string,
    organs_purpose: string
  };
  FuneralArrangementDataError: {
    specify_funeral_arrangement: string | null,
    specify_buried_or_cremated: string | null,
    any_other_funeral_requirement: string | null,
    organs_donation: string | null,
    Yes_all_organs: string | null,
    specific_organs: string | null,
    organs_purpose: string | null
  };
  AppointingGuardianData: {
    define_children_under_age_of_18: string,
    appoint_guardian: string,
    child1_name: string,
    child1_address: string,
    child1_age: string,
    child1_sex: string,
    doyou_require: string,
    wishes_for_guardian: string
  };
  AppointingGuardianDataError: {
    define_children_under_age_of_18: string | null,
    appoint_guardian: string | null,
    child1_name: string | null,
    child1_address: string | null,
    child1_age: string | null,
    child1_sex: string | null,
    doyou_require: string | null,
    wishes_for_guardian: string | null
  };
  LeaveGiftData: {
    leave_gift_of_money: string,
    giftMoneyList: { [key: string]: (string | { name: string, code: string, path: string }) }[],
    leave_any_specific_gift: string,
    giftList: { [key: string]: (string | { name: string, code: string, path: string }) }[],
    leave_gift_of_property: string,
    define_you_leave_property_to: string,
    propertyPersonsList: { [key: string]: string | {} }[],
    define_when_personal_possession_not_specify: string,
    recipientList: { [key: string]: (string | { name: string, code: string, path: string }) }[],
    who_pay_inheritance_tax: string
  };
  LeaveGiftDataError: {
    leave_gift_of_money: string | null,
    giftMoneyList: { [key: string]: string | null }[],
    leave_any_specific_gift: string | null,
    giftList: { [key: string]: string | null }[],
    leave_gift_of_property: string | null,
    propertyPersonsList: { [key: string]: string | null }[],
    define_when_personal_possession_not_specify: string | null,
    recipientList: { [key: string]: string | null }[],
    who_pay_inheritance_tax: string | null
  };
  ExemptionData: {
    spouse_get_benifit_from_this_will: string,
    any_gift_of_children_will_fail: string,
    deliberately_excluding_any_person: string,
    excluded_person_name: string,
    excluded_person_age: string,
    excluded_person_address: string,
  };
  ExemptionDataError: {
    spouse_get_benifit_from_this_will: string | null,
    any_gift_of_children_will_fail: string | null,
    deliberately_excluding_any_person: string | null,
    excluded_person_name: string | null,
    excluded_person_age: string | null,
    excluded_person_address: string | null,
  };
  PetsData: {
    provision_for_pets: string,
    name_of_carer: string,
    address_of_carer: string,
    kind_of_pet: string,
    name_of_pet: string,
    any_pet_care_by_rspca_home: string,
    leave_gift_of_money_for_pet: string,
    define_money_leave_for_pet: string
  };
  PetsDataError: {
    provision_for_pets: string | null,
    name_of_carer: string | null,
    address_of_carer: string | null,
    kind_of_pet: string | null,
    name_of_pet: string | null,
    any_pet_care_by_rspca_home: string | null,
    leave_gift_of_money_for_pet: string | null,
    define_money_leave_for_pet: string | null
  };
  ResidueEstateData: {
    residue_estate: string,
    residue_estate_person_info: { [key: string]: string | {} }[],
    for_Myself: boolean,
    haveMental_Capacity: boolean,
    capacity_Vault: boolean
  };
  ResidueEstateDataError: {
    residue_estate: string | null,
    residue_estate_person_info: { [key: string]: string | null }[],
    for_Myself: string | null,
    haveMental_Capacity: string | null,
    capacity_Vault: string | null
  };
  CountryList: {}[],
  cartProduct: any[],
  value: any,
  open: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomForm2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetCartApiCallId: string = "";
  createCustomFormCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openThankyouModal: false,
      flag: true,
      formFlag: "PersonalInfo1",
      // formFlag: "LeaveaGift",
      PersonalInfoData: {
        person1_name: "",
        person1_age: "",
        person1_address: "",
        person1_phone_number: "+44",
        person1_country_code: {
          name: "UK",
          code: "+44",
          path: UKflag
        },
        person1_email: "",
        person1_marital_status: "Select",
        person1_confirm_assets: "Select",
        person2_name: "",
        person2_age: "",
        person2_address: "",
        person2_phone_number: "+44",
        person2_country_code: {
          name: "UK",
          code: "+44",
          path: UKflag
        },
        person2_email: "",
        person2_marital_status: "Select",
        person2_confirm_assets: "Select"
      },
      PersonalInfoDataError: {
        person1_name: null,
        person1_age: null,
        person1_address: null,
        person1_phone_number: null,
        person1_email: null,
        person1_marital_status: null,
        person1_confirm_assets: null,
        person2_name: null,
        person2_age: null,
        person2_address: null,
        person2_phone_number: null,
        person2_email: null,
        person2_marital_status: null,
        person2_confirm_assets: null
      },
      PersonalInfoDataSingle: {
        person_name: "",
        person_age: "",
        person_address: "",
        person_phone_number: "+44",
        country_code: {
          name: "UK",
          code: "+44",
          path: UKflag
        },
        person_email: "",
        person_marital_status: "Select",
        person_confirm_assets: "Select",
      },
      PersonalInfoDataSingleError: {
        person_name: null,
        person_age: null,
        person_address: null,
        person_phone_number: null,
        person_email: null,
        person_marital_status: null,
        person_confirm_assets: null,
      },
      ExecutorsData: {
        executorsList: [
          {
            executor_person1_name: "",
            executor_person1_address: "",
            executor_person1_phone_number: "+44",
            executor_person1_country_code: {
              name: "UK",
              code: "+44",
              path: UKflag
            },
            executor_person1_relation: "",
          }
        ],
        executor_diff_trustees: "Select",
        executor_reach_certain_age: "Select",
        executor_enter_age: ""
      },
      ExecutorsDataError: {
        executorsList: [
          {
            executor_person1_name: null,
            executor_person1_address: null,
            executor_person1_phone_number: null,
            executor_person1_relation: null,
          }
        ],
        executor_diff_trustees: null,
        executor_reach_certain_age: null,
        executor_enter_age: null
      },
      FuneralArrangementData: {
        specify_funeral_arrangement: "Select",
        specify_buried_or_cremated: "Select",
        any_other_funeral_requirement: "",
        organs_donation: "Select",
        Yes_all_organs: "Select",
        specific_organs: "",
        organs_purpose: "Select"
      },
      FuneralArrangementDataError: {
        specify_funeral_arrangement: null,
        specify_buried_or_cremated: null,
        any_other_funeral_requirement: null,
        organs_donation: null,
        Yes_all_organs: null,
        specific_organs: null,
        organs_purpose: null
      },
      AppointingGuardianData: {
        define_children_under_age_of_18: "Select",
        appoint_guardian: "Select",
        child1_name: "",
        child1_address: "",
        child1_age: "",
        child1_sex: "",
        doyou_require: "",
        wishes_for_guardian: ""
      },
      AppointingGuardianDataError: {
        define_children_under_age_of_18: null,
        appoint_guardian: null,
        child1_name: null,
        child1_address: null,
        child1_age: null,
        child1_sex: null,
        doyou_require: null,
        wishes_for_guardian: null
      },
      LeaveGiftData: {
        leave_gift_of_money: "Select",
        giftMoneyList: [{
          giftMoney1_name: "",
          giftMoney1_address: "",
          giftMoney1_dob: "",
          define_you_leave_giftMoney1_to: "",
          giftMoney1_phone_number: "+44",
          giftMoney1_country_code: {
            name: "UK",
            code: "+44",
            path: UKflag
          },
          giftMoney1_email: "",
        }],
        leave_any_specific_gift: "Select",
        giftList: [{
          what_is_gift1: "",
          define_you_leave_gift1_to: "",
          gift1_name: "",
          gift1_address: "",
          gift1_phone_number: "+44",
          gift1_country_code: {
            name: "UK",
            code: "+44",
            path: UKflag
          },
          gift1_email: "",
          gift1_transportation_cost: "Select",
          gift1_inheritance_cost: "Select",
        }],
        leave_gift_of_property: "Select",
        define_you_leave_property_to: "",
        propertyPersonsList: [{
          gift_of_property1_name: "",
          gift_of_property1_address: "",
          gift_of_property1_phone_number: "+44",
          gift_of_property1_country_code: {
            name: "UK",
            code: "+44",
            path: UKflag
          },
          gift_of_property1_email: "",
          gift_of_property1_inheritance_tax_be_paid: "Select",
          gift_of_property1_expenses_be_paid: "Select",
          gift_of_property1_include_any_main_residence: "Select",
          gift_of_property1_include_property_content: "Select",
        }],
        define_when_personal_possession_not_specify: "Select",
        recipientList: [{
          recipient1_name: "",
          recipient1_address: "",
          recipient1_phone_number: "+44",
          recipient1_country_code: {
            name: "UK",
            code: "+44",
            path: UKflag
          },
          recipient1_email: "",
        }],
        who_pay_inheritance_tax: "Select"
      },
      LeaveGiftDataError: {
        leave_gift_of_money: " ",
        giftMoneyList: [{
          giftMoney1_name: "",
          giftMoney1_address: "",
          giftMoney1_dob: "",
          define_you_leave_giftMoney1_to: "",
          giftMoney1_phone_number: "+44",
          giftMoney1_email: "",
        }],
        leave_any_specific_gift: " ",
        giftList: [
          {
            what_is_gift1: " ",
            define_you_leave_gift1_to: " ",
            gift1_name: " ",
            gift1_address: " ",
            gift1_phone_number: "+44",
            gift1_email: " ",
            gift1_transportation_cost: " ",
            gift1_inheritance_cost: " ",
          }
        ],
        leave_gift_of_property: " ",
        propertyPersonsList: [{
          gift_of_property1_name: " ",
          gift_of_property1_address: " ",
          gift_of_property1_phone_number: "+44",
          gift_of_property1_email: " ",
          gift_of_property1_inheritance_tax_be_paid: " ",
          gift_of_property1_expenses_be_paid: " ",
          gift_of_property1_include_any_main_residence: " ",
          gift_of_property1_include_property_content: " ",
        }],
        define_when_personal_possession_not_specify: " ",
        recipientList: [{
          recipient1_name: "",
          recipient1_address: "",
          recipient1_phone_number: "+44",
          recipient1_email: ""
        }],
        who_pay_inheritance_tax: " "
      },
      ExemptionData: {
        spouse_get_benifit_from_this_will: "Select",
        any_gift_of_children_will_fail: "Select",
        deliberately_excluding_any_person: "Select",
        excluded_person_name: "",
        excluded_person_age: "",
        excluded_person_address: "",
      },
      ExemptionDataError: {
        spouse_get_benifit_from_this_will: null,
        any_gift_of_children_will_fail: null,
        deliberately_excluding_any_person: null,
        excluded_person_name: null,
        excluded_person_age: null,
        excluded_person_address: null,
      },
      PetsData: {
        provision_for_pets: "Select",
        name_of_carer: "",
        address_of_carer: "",
        kind_of_pet: "",
        name_of_pet: "",
        any_pet_care_by_rspca_home: "Select",
        leave_gift_of_money_for_pet: "Select",
        define_money_leave_for_pet: ""
      },
      PetsDataError: {
        provision_for_pets: null,
        name_of_carer: null,
        address_of_carer: null,
        kind_of_pet: null,
        name_of_pet: null,
        any_pet_care_by_rspca_home: null,
        leave_gift_of_money_for_pet: null,
        define_money_leave_for_pet: null
      },
      ResidueEstateData: {
        residue_estate: "Select",
        residue_estate_person_info: [{
          estate_person_name1: "",
          estate_person_address1: "",
          estate_person_amount1: "",
        }],
        for_Myself: false,
        haveMental_Capacity: false,
        capacity_Vault: false,
      },
      ResidueEstateDataError: {
        residue_estate: null,
        residue_estate_person_info: [{
          estate_person_name1: null,
          estate_person_address1: null,
          estate_person_amount1: null,
        }],
        for_Myself: null,
        haveMental_Capacity: null,
        capacity_Vault: null,
      },
      CountryList: [
        {
          name: "UK",
          code: "+44",
          path: UKflag
        },
        {
          name: "ENG",
          code: "+44",
          path: EnglandFlag
        },
        {
          name: "SCT",
          code: "+44",
          path: ScotlandFlag
        },
        {
          name: "WLS",
          code: "+44",
          path: WalesFlag
        },
        {
          name: "NIR",
          code: "+44",
          path: NIRflag
        },
      ],
      // attributes:{
      //   product_name:""
      // },
      cartProduct: [],
      value: "",
      open: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }


  async componentDidMount() {
    this.getCartData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start


    const matchWithCallIdHandler = (apiRequestCallId: any, responseJson: any) => {
      if (apiRequestCallId === this.createCustomFormCallId) {
        this.createCustomFormCallIdHandler(responseJson)
      }

      if (apiRequestCallId === this.GetCartApiCallId) {
        this.GetCartApiCallIdHandler(responseJson);
      }
    }


    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      matchWithCallIdHandler(apiRequestCallId, responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  WillListlengthHandler = (willList: any, resp: any, selectedWillName: any) => {
    if (willList.length > 1) {
      let filteredList: any = willList?.filter((item: any) => {
        if (item.attributes.product_name === selectedWillName) {
          return true;
        }
        else {
          return false;
        }
      }

      );
      this.setState({
        cartProduct: filteredList,
      });

    }

    else {
      this.setState({
        cartProduct: resp?.data[0].attributes?.order_items,
      });

    }

  }


  GetCartApiCallIdHandler = (resp: any) => {
    resp.data &&
      this.WillListlengthHandler(resp?.data[0].attributes?.order_items, resp, this.props.history.location.state?.cartData.selectedWill)
  }

  createCustomFormCallIdHandler = (resp: any) => {
    if (resp.data) {
      this.setState({
        openThankyouModal: true
      })
    }
    else {
      toast.error(resp?.error)
    }
  }

  getCartData = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCartApiCallId = requestMessage.messageId;
    let buyNowCartId = this.props.history.location.state?.cartData.buyNowCart_Id;


    if (buyNowCartId) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetIsCartCreated +
        `?cart_id=${buyNowCartId}`
      );
    } else {

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetIsCartCreated
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  showForm = (item: string): void => {
    this.setState({ formFlag: item })
  }

  userInputHandler = (event: any) => {
    if (event.target.name === "person1_country_code") {
      this.setState({
        PersonalInfoData: {
          ...this.state.PersonalInfoData,
          [event.target.name]: event.target.value,
          person1_phone_number: event.target.value.code
        }
      })
    }
    else if (event.target.name === "person2_country_code") {
      this.setState({
        PersonalInfoData: {
          ...this.state.PersonalInfoData,
          [event.target.name]: event.target.value,
          person2_phone_number: event.target.value.code
        }
      })

    }
    else {
      this.setState({
        PersonalInfoData: {
          ...this.state.PersonalInfoData,
          [event.target.name]: event.target.value
        }
      })
    }

    let errorIs = this.validateMirror(event);
    if (event.target.name === "person1_age") {
      errorIs = this.validateDOBInput(event)
    }
    if (event.target.name === "person2_age") {
      errorIs = this.validateDOBInput(event)
    }
    this.setState({
      PersonalInfoDataError: {
        ...this.state.PersonalInfoDataError,
        [event.target.name]: errorIs
      }
    })

  }

  calculateAgeDiff = (date: any) => {
    const oldYear = date.slice(0, 4);
    let currentYear = new Date().getFullYear();
    return (currentYear - oldYear).toString();

  }

  userBirthDateHandler = (date: any) => {
    this.setState({
      PersonalInfoData: {
        ...this.state.PersonalInfoData,
        person1_age: date
      }
    })


    let errorIs = this.validateDOBInput(date);
    this.setState({
      PersonalInfoDataError: {
        ...this.state.PersonalInfoDataError,
        person1_age: errorIs
      }
    })
  }

  userBirthDatePerson2Handler = (date: any) => {
    this.setState({
      PersonalInfoData: {
        ...this.state.PersonalInfoData,
        person2_age: date
      }
    })


    let errorIs = this.validateDOBInput(date);
    this.setState({
      PersonalInfoDataError: {
        ...this.state.PersonalInfoDataError,
        person2_age: errorIs
      }
    })
  }

  validateDOBInput = (e: any) => {
    const dateValue = typeof e === "string" ? e : e.target.value
    if (!dateValue || dateValue === "") {
      return "value required";
    }
    // else if(/^(?:[1-9]'\d'?|100)$/.test(dateValue))
    else if (/^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(dateValue)) {
      return "";
    }
    else {
      return "please enter valid birth date.";
    }
  }

  userInputHandlerSingle = (event: any) => {
    if (event.target.name === "country_code") {
      this.setState({
        PersonalInfoDataSingle: {
          ...this.state.PersonalInfoDataSingle,
          [event.target.name]: event.target.value,
          person_phone_number: event.target.value.code
        }
      })
    }
    else {
      this.setState({
        PersonalInfoDataSingle: {
          ...this.state.PersonalInfoDataSingle,
          [event.target.name]: event.target.value
        }
      })
    }

    let errorIs = this.validatePersonalSingleError(event);
    if (event.target.name === "person_age") {
      errorIs = this.validateDOBInput(event)
    }

    this.setState({
      PersonalInfoDataSingleError: {
        ...this.state.PersonalInfoDataSingleError,
        [event.target.name]: errorIs
      }
    })
  }

  singleUserBirthDatePerson2Handler = (date: any) => {
    this.setState({
      PersonalInfoDataSingle: {
        ...this.state.PersonalInfoDataSingle,
        person_age: date
      }
    })

    let errorIs = this.validateDOBInput(date);
    this.setState({
      PersonalInfoDataSingleError: {
        ...this.state.PersonalInfoDataSingleError,
        person_age: errorIs
      }
    })
  }

  userInputHandlerExecutor = (event: any) => {

    if (event.target.name === "executor_person1_country_code") {
      this.setState({
        ExecutorsData: {
          ...this.state.ExecutorsData,
          [event.target.name]: event.target.value,
        }
      })
    }
    else {
      this.setState({
        ExecutorsData: {
          ...this.state.ExecutorsData,
          [event.target.name]: event.target.value
        }
      })
    }

    let errorIs = this.validateExecutor(event, -1);
    this.setState({
      ExecutorsDataError: {
        ...this.state.ExecutorsDataError,
        [event.target.name]: errorIs
      }
    })

  }

  userInputHandlerExecutorList = (event: { target: { name: string, value: string | any } }, index: number) => {
    let newFieldList: { [key: string]: string | {} }[] = [...this.state.ExecutorsData.executorsList];
    if (event.target.name === "executor_person" + (index + 1) + "_country_code") {
      newFieldList[index][event.target.name] = event.target.value;
      newFieldList[index]['executor_person' + (index + 1) + '_phone_number'] = event.target.value.code;
      this.setState({
        ExecutorsData: {
          ...this.state.ExecutorsData,
          executorsList: newFieldList
        }
      })
    }
    else {
      newFieldList[index][event.target.name] = event.target.value;
      this.setState({
        ExecutorsData: {
          ...this.state.ExecutorsData,
          executorsList: newFieldList
        }
      })
    }

    if (event.target.name !== "executor_person" + (index + 1) + "_country_code") {
      let errorIs = this.validateExecutor(event, index);
      let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.ExecutorsDataError.executorsList];
      newErrorFieldList[index][event.target.name] = errorIs;
      this.setState({
        ExecutorsDataError: {
          ...this.state.ExecutorsDataError,
          executorsList: newErrorFieldList
        }
      })
    }

  }

  userInputHandlerFuneral = (event: any) => {
    this.setState({
      FuneralArrangementData: {
        ...this.state.FuneralArrangementData,
        [event.target.name]: event.target.value
      }
    })

    let errorIs = this.validateFuneral(event);
    this.setState({
      FuneralArrangementDataError: {
        ...this.state.FuneralArrangementDataError,
        [event.target.name]: errorIs
      }
    })
  }

  AppointingGuardianHandler = (event: any) => {
    this.setState({
      AppointingGuardianData: {
        ...this.state.AppointingGuardianData,
        [event.target.name]: event.target.value
      }
    })

    let errorIs = this.validateAppointing(event);
    this.setState({
      AppointingGuardianDataError: {
        ...this.state.AppointingGuardianDataError,
        [event.target.name]: errorIs
      }
    })
  }

  LeaveGiftHandler = (event: any) => {

    if (event.target.name === "gift1_country_code") {
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          [event.target.name]: event.target.value,
        }
      })
    }
    else {
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          [event.target.name]: event.target.value
        }
      })
    }

    let errorIs = this.validateLeaveaGift(event, -1);
    this.setState({
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        [event.target.name]: errorIs
      }
    })
  }

  LeavePropertyListHandler = (event: { target: { value: string | any, name: string } }, index: number) => {

    if (event.target.name === "gift_of_property" + (index + 1) + "_country_code") {
      let newPropertyList = [...this.state.LeaveGiftData.propertyPersonsList];
      newPropertyList[index][event.target.name] = event.target.value;
      newPropertyList[index]['gift_of_property' + (index + 1) + '_phone_number'] = event.target.value.code;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          propertyPersonsList: newPropertyList
        }
      })
    }
    else {
      let newPropertyList = [...this.state.LeaveGiftData.propertyPersonsList];
      newPropertyList[index][event.target.name] = event.target.value;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          propertyPersonsList: newPropertyList
        }
      })
    }

    if (event.target.name !== "gift_of_property" + (index + 1) + "_country_code") {
      let errorIs = this.validateLeaveaGift(event, index);
      let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.LeaveGiftDataError.propertyPersonsList];
      newErrorFieldList[index][event.target.name] = errorIs;
      this.setState({
        LeaveGiftDataError: {
          ...this.state.LeaveGiftDataError,
          propertyPersonsList: newErrorFieldList
        }
      })
    }
  }

  LeaveGiftListHandler = (event: { target: { value: string | any, name: string } }, index: number) => {
    if (event.target.name === 'gift' + (index + 1) + '_country_code') {
      let newGiftList = [...this.state.LeaveGiftData.giftList];
      newGiftList[index][event.target.name] = event.target.value;
      newGiftList[index][`gift${index + 1}_phone_number`] = event.target.value.code;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          giftList: newGiftList
        }
      })
    }
    else {
      let newGiftList = [...this.state.LeaveGiftData.giftList];
      newGiftList[index][event.target.name] = event.target.value;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          giftList: newGiftList
        }
      })
    }

    if (event.target.name !== 'gift' + (index + 1) + '_country_code') {
      let errorIs = this.validateLeaveaGift(event, index);
      let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.LeaveGiftDataError.giftList];
      newErrorFieldList[index][event.target.name] = errorIs;
      this.setState({
        LeaveGiftDataError: {
          ...this.state.LeaveGiftDataError,
          giftList: newErrorFieldList
        }
      })
    }
  }

  LeaveGiftMoneyListHandler = (event: { target: { value: string | any, name: string } }, index: number) => {
    if (event.target.name === 'giftMoney' + (index + 1) + '_country_code') {
      let newGiftMoneyList = [...this.state.LeaveGiftData.giftMoneyList];
      newGiftMoneyList[index][event.target.name] = event.target.value;
      newGiftMoneyList[index][`giftMoney${index + 1}_phone_number`] = event.target.value.code;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          giftMoneyList: newGiftMoneyList
        }
      })
    }
    else {
      let newGiftMoneyList = [...this.state.LeaveGiftData.giftMoneyList];
      newGiftMoneyList[index][event.target.name] = event.target.value;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          giftMoneyList: newGiftMoneyList
        }
      })
    }

    if (event.target.name !== 'giftMoney' + (index + 1) + '_country_code') {
      let errorIs = this.validateLeaveaGift(event, index);
      let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.LeaveGiftDataError.giftMoneyList];
      newErrorFieldList[index][event.target.name] = errorIs;
      this.setState({
        LeaveGiftDataError: {
          ...this.state.LeaveGiftDataError,
          giftMoneyList: newErrorFieldList
        }
      })
    }
  }

  LeaveRecipientListHandler = (event: { target: { value: string | any, name: string } }, index: number) => {
    if (event.target.name === 'recipient' + (index + 1) + '_country_code') {
      let newRecipientList = [...this.state.LeaveGiftData.recipientList];
      newRecipientList[index][event.target.name] = event.target.value;
      newRecipientList[index][`recipient${index + 1}_phone_number`] = event.target.value.code;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          recipientList: newRecipientList
        }
      })
    }
    else {
      let newRecipientList = [...this.state.LeaveGiftData.recipientList];
      newRecipientList[index][event.target.name] = event.target.value;
      this.setState({
        LeaveGiftData: {
          ...this.state.LeaveGiftData,
          recipientList: newRecipientList
        }
      })
    }

    if (event.target.name !== 'recipient' + (index + 1) + '_country_code') {
      let errorIs = this.validateLeaveaGift(event, index);
      let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.LeaveGiftDataError.recipientList];
      newErrorFieldList[index][event.target.name] = errorIs;
      this.setState({
        LeaveGiftDataError: {
          ...this.state.LeaveGiftDataError,
          recipientList: newErrorFieldList
        }
      })
    }
  }

  ExemptionDataHandler = (event: any) => {
    this.setState({
      ExemptionData: {
        ...this.state.ExemptionData,
        [event.target.name]: event.target.value
      }
    })

    let errorIs = this.validateExemptionHandler(event);
    this.setState({
      ExemptionDataError: {
        ...this.state.ExemptionDataError,
        [event.target.name]: errorIs
      }
    })
  }

  PetsDataHandler = (event: { target: { value: string, name: string } }) => {
    this.setState({
      PetsData: {
        ...this.state.PetsData,
        [event.target.name]: event.target.value
      }
    })
    let errorIs = this.validatePetsHandler(event);
    this.setState({
      PetsDataError: {
        ...this.state.PetsDataError,
        [event.target.name]: errorIs
      }
    })
  }

  ResidueDataHandler = (event: { target: { value: string, name: string, checked: boolean } }) => {
    if (event.target.name === "for_Myself" || event.target.name === "haveMental_Capacity" || event.target.name === "capacity_Vault") {
      this.setState({
        ResidueEstateData: {
          ...this.state.ResidueEstateData,
          [event.target.name]: event.target.checked
        }
      })

    }
    else {
      this.setState({
        ResidueEstateData: {
          ...this.state.ResidueEstateData,
          [event.target.name]: event.target.value
        }
      })
    }

    if (event.target.name !== "capacity_Vault") {
      let errorIs = this.validateResidueEstateHandler(event, -1);
      this.setState({
        ResidueEstateDataError: {
          ...this.state.ResidueEstateDataError,
          [event.target.name]: errorIs
        }
      })
    }
  }

  ResiduePersonsHandler = (event: { target: { value: string, name: string, checked: boolean } }, index: number) => {
    let newEstateList = [...this.state.ResidueEstateData.residue_estate_person_info];
    newEstateList[index][event.target.name] = event.target.value;
    this.setState({
      ResidueEstateData: {
        ...this.state.ResidueEstateData,
        residue_estate_person_info: newEstateList
      }
    })

    let errorIs = this.validateResidueEstateHandler(event, index)
    let newErrorFieldList: { [key: string]: string | null }[] = [...this.state.ResidueEstateDataError.residue_estate_person_info];
    newErrorFieldList[index][event.target.name] = errorIs;
    this.setState({
      ResidueEstateDataError: {
        ...this.state.ResidueEstateDataError,
        residue_estate_person_info: newErrorFieldList
      }
    })
  }


  validateNameInput = (e: any) => {
    if (!e.target.value || e.target.value === "") {
      return "value required";
    }
    else if (/^(?!.*\s$)[A-Za-z\s]{2,}$/.test(e.target.value)) {
      return "";
    }
    else {
      return "please enter valid name";
    }
  }

  validateAgeInput = (e: any) => {
    if (!e.target.value || e.target.value === "") {
      return "value required";
    }
    // else if(/^(?:[1-9]'\d'?|100)$/.test(e.target.value))
    else if (/^([5-9]|[1-9]\d|1[0-2]\d|130)$/.test(e.target.value)) {
      return "";
    }
    else {
      return "please enter valid age";
    }
  }

  validateAddress = (e: { target: { value: string } }) => {
    if (!e.target.value || e.target.value === "") {
      return "value is required";
    }
    else if (/^(?!.*\s$)[A-Za-z0-9\s.,'-]{5,}$/.test(e.target.value)) {
      return "";
    }
    else {
      return "please enter valid address";
    }
  }

  validatePhoneNumber = (e: any) => {
    if (!e.target.value || e.target.value === "") {
      return "value is required";
    }
    else if (e.target.value.slice(0, 1).startsWith("+") && /^\d{11}$/.test(e.target.value.slice(3))) {
      return "";
    }
    else {
      return "please enter valid mobile number";
    }
  }

  validateEmailInput = (e: { target: { value: string } }) => {
    if (!e.target.value || e.target.value === "") {
      return "value is required";
    }
    else if (/^[a-zA-Z0-9._]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/.test(e.target.value)) {
      return "";
    }
    else {
      return "please enter valid email";
    }
  }

  validateDropdownInput = (e: any) => {
    if (!e.target.value || (e.target.value === "Select")) {
      return "Please select any one option";
    }
    else {
      return "";
    }
  }

  validateTextInput = (e: { target: { value: string } }) => {
    if (!e.target.value || (e.target.value === "")) {
      return "value is required";
    }
    else {
      return "";
    }
  }

  validateMoneyInput = (e: { target: { value: string } }) => {
    if (!e.target.value || e.target.value === "") {
      return "value required";
    }
    else if (/^\d+$/.test(e.target.value)) {
      return "";
    }
    else {
      return "enter valid money value";
    }
  }

  validateMirror = (e: any) => {
    switch (e.target.name) {
      case "person1_name":
      case "person2_name":
        return this.validateNameInput(e);
      case "person1_age":
      case "person2_age":
        return this.validateAgeInput(e);
      case "person1_address":
      case "person2_address":
        if (!e.target.value || e.target.value === "") {
          return "value is required";
        }
        else if (/^(?!.*\s$)[A-Za-z0-9\s.,'-]{5,}$/.test(e.target.value)) {
          return "";
        }
        else {
          return "please enter valid email";
        }
      case "person1_phone_number":
      case "person2_phone_number":
        return this.validatePhoneNumber(e);
      case "person1_email":
      case "person2_email":
        return this.validateEmailInput(e);
      case "person1_marital_status":
      case "person2_marital_status":
      case "person1_confirm_assets":
      case "person2_confirm_assets":
        return this.validateDropdownInput(e);
      default:
        return null;
    }
  }

  validatePersonalSingleError = (e: { target: { value: string, name: string } }) => {
    switch (e.target.name) {
      case "person_name":
        return this.validateNameInput(e);
      case "person_age":
        return this.validateAgeInput(e);
      case "person_address":
        return this.validateAddress(e);
      case "person_phone_number":
        return this.validatePhoneNumber(e);
      case "person_email":
        return this.validateEmailInput(e);
      case "person_marital_status":
      case "person_confirm_assets":
        return this.validateDropdownInput(e);
      default:
        return null;
    }
  }

  validateExecutor = (e: { target: { value: string, name: string } }, index: number) => {
    switch (e.target.name) {
      case 'executor_person' + (index + 1) + '_name':
        return this.validateNameInput(e);
      case "executor_person" + (index + 1) + "_address":
        return this.validateAddress(e);
      case "executor_person" + (index + 1) + "_phone_number":
        return this.validatePhoneNumber(e);
      case "executor_person" + (index + 1) + "_relation":
        return this.validateNameInput(e);
      case "executor_diff_trustees":
      case "executor_reach_certain_age":
        return this.validateDropdownInput(e);
      case "executor_enter_age":
        return this.validateAgeInput(e);
      default:
        return null;
    }
  }

  validateFuneral = (e: { target: { value: string, name: string } }) => {
    switch (e.target.name) {
      case "specify_funeral_arrangement":
      case "specify_buried_or_cremated":
      case "organs_donation":
      case "Yes_all_organs":
      case "organs_purpose":
        return this.validateDropdownInput(e);
      case "any_other_funeral_requirement":
      case "specific_organs":
        return this.validateTextInput(e);
      default:
        return null;
    }
  }

  validateAppointing = (e: { target: { value: string, name: string } }) => {
    switch (e.target.name) {
      case "define_children_under_age_of_18":
      case "appoint_guardian":
        return this.validateDropdownInput(e);
      case "child1_name":
      case "child1_sex":
        return this.validateNameInput(e);
      case "child1_address":
        return this.validateAddress(e);
      case "wishes_for_guardian":
      case "doyou_require":
        return this.validateTextInput(e);
      case "child1_age":
        return this.validateAgeInput(e);
      default:
        return null;
    }
  }

  validateLeaveaGift = (e: { target: { value: string, name: string } }, index: number) => {
    switch (e.target.name) {
      case "leave_gift_of_money":
      case "leave_any_specific_gift":
      case 'gift' + (index + 1) + '_transportation_cost':
      case 'gift' + (index + 1) + '_inheritance_cost':
      case "leave_gift_of_property":
      case 'gift_of_property' + (index + 1) + '_inheritance_tax_be_paid':
      case "who_pay_inheritance_tax":
      case 'gift_of_property' + (index + 1) + '_expenses_be_paid':
      case 'gift_of_property' + (index + 1) + '_include_any_main_residence':
      case 'gift_of_property' + (index + 1) + '_include_property_content':
      case "define_when_personal_possession_not_specify":
        return this.validateDropdownInput(e);
      case 'what_is_gift' + (index + 1):
        return this.validateTextInput(e);
      case 'gift_of_property' + (index + 1) + '_name':
      case 'define_you_leave_gift' + (index + 1) + '_to':
      case 'define_you_leave_giftMoney' + (index + 1) + '_to':
      case 'gift' + (index + 1) + '_name':
      case 'giftMoney' + (index + 1) + '_name':
      case 'recipient' + (index + 1) + '_name':
        return this.validateNameInput(e);
      case "define_you_leave_property_to":
        if (!e.target.value || e.target.value === "") {
          return "value required";
        }
        else if (/^\d+$/.test(e.target.value)) {
          return "";
        }
        else {
          return "enter valid value";
        }
      case 'gift' + (index + 1) + '_address':
      case 'giftMoney' + (index + 1) + '_address':
      case 'gift_of_property' + (index + 1) + '_address':
      case 'recipient' + (index + 1) + '_address':
        return this.validateAddress(e);
      case 'gift' + (index + 1) + '_phone_number':
      case 'giftMoney' + (index + 1) + '_phone_number':
      case 'gift_of_property' + (index + 1) + '_phone_number':
      case 'recipient' + (index + 1) + '_phone_number':
        return this.validatePhoneNumber(e);
      case 'gift' + (index + 1) + '_email':
      case 'giftMoney' + (index + 1) + '_email':
      case 'gift_of_property' + (index + 1) + '_email':
      case 'recipient' + (index + 1) + '_email':
        return this.validateEmailInput(e);
      case 'giftMoney' + [index + 1] + '_dob':
        return this.validateDOBInput(e);
      default:
        return null;

    }
  }

  validateExemptionHandler = (e: any) => {
    switch (e.target.name) {
      case "spouse_get_benifit_from_this_will":
      case "any_gift_of_children_will_fail":
      case "deliberately_excluding_any_person":
        if (!e.target.value) {
          return "value is required";
        }
        else {
          return "";
        }
      case "excluded_person_name":
        return this.validateNameInput(e);
      case "excluded_person_address":
        return this.validateAddress(e);
      case "excluded_person_age":
        return this.validateAgeInput(e);
      default:
        return null;
    }
  }

  validatePetsHandler = (e: { target: { value: string, name: string } }) => {
    switch (e.target.name) {
      case "provision_for_pets":
      case "any_pet_care_by_rspca_home":
      case "leave_gift_of_money_for_pet":
        return this.validateDropdownInput(e);
      case "name_of_carer":
      case "name_of_pet":
        return this.validateNameInput(e);
      case "kind_of_pet":
        return this.validateTextInput(e);
      case "address_of_carer":
        return this.validateAddress(e);
      case "excluded_person_age":
        return this.validateAgeInput(e);
      case "define_money_leave_for_pet":
        return this.validateMoneyInput(e);
      default:
        return null;
    }
  }

  validateResidueEstateHandler = (e: { target: { value: string, name: string, checked: boolean } }, index: number) => {
    switch (e.target.name) {
      case "residue_estate":
        return this.validateDropdownInput(e);
      case "estate_person_name" + (index + 1):
        return this.validateNameInput(e);
      case "estate_person_address" + (index + 1):
        return this.validateAddress(e);
      case "estate_person_amount" + (index + 1):
        return this.validateMoneyInput(e);
      case "for_Myself":
      case "haveMental_Capacity":
        if (!e.target.checked) {
          return "value required";
        }
        else {
          return "";
        }
      default:
        return null;

    }
  }

  createCustomFormApiCall = async () => {

    let OrderId = this.props?.history.location?.state?.cartData?.orderIdIs;
    let personalInfobody = this.singleMirroWillData();
    let organDonation = this.organDonationData();
    let leaveGiftList = this.leaveGiftDataHandler();
    let leaveGiftMoneyList = this.leaveGiftMoneyDataHandler();
    let leaveGiftPropertList = this.LeavePropertyHandler();
    let personalPossessionsRecipientList = this.LeaveRecipientHandler();
    let appointGuardian;
    let provisionData;
    let appointGuardianData;
    let howManyPeople;
    let exemptionPersons;
    let petsMoney;


    if (this.state.AppointingGuardianData.define_children_under_age_of_18 === "Yes") {
      appointGuardian = {
        "would_you_like_appoint_guardian": this.state.AppointingGuardianData.appoint_guardian
      }
    }
    else if (this.state.AppointingGuardianData.define_children_under_age_of_18 === "No") {
      appointGuardian = {
        "would_you_like_appoint_guardian": null
      }
    }

    if (this.state.AppointingGuardianData.appoint_guardian === "Yes") {
      appointGuardianData = {
        "name_of_child": this.state.AppointingGuardianData.child1_name,
        "address_of_child": this.state.AppointingGuardianData.child1_address,
        "age_of_child": this.state.AppointingGuardianData.child1_age,
        "sex_of_child": this.state.AppointingGuardianData.child1_sex,
        "do_you_require": this.state.AppointingGuardianData.doyou_require,
        "wishes_for_guardian": this.state.AppointingGuardianData.wishes_for_guardian
      }
    }
    else if (this.state.AppointingGuardianData.appoint_guardian === "No") {
      appointGuardian = {
        "name_of_child": null,
        "address_of_child": null,
        "age_of_child": null,
        "sex_of_child": null,
        "do_you_require": null,
        "wishes_for_guardian": null
      }
    }

    if (this.state.PetsData.provision_for_pets === "Yes") {
      provisionData = {
        "name_of_carer": this.state.PetsData.name_of_carer,
        "address_of_carer": this.state.PetsData.address_of_carer,
        "kind_of_pet": this.state.PetsData.kind_of_pet,
        "name_of_pet": this.state.PetsData.name_of_pet,
        "shall_any_pet_care_by_rspca_home": this.state.PetsData.any_pet_care_by_rspca_home,
      }
    }
    else if (this.state.PetsData.provision_for_pets === "No") {
      provisionData = null
    }

    const execList = this.state.ExecutorsData.executorsList;
    const executorConvrtedList = execList.map((item, index) => {
      return {
        "executor_name": item['executor_person' + (index + 1) + '_name'],
        "executor_address": item['executor_person' + (index + 1) + '_address'],
        "executor_phone_number": (item['executor_person' + (index + 1) + '_phone_number'] as string).slice(-11),
        "executor_country": (item['executor_person' + (index + 1) + '_country_code'] as { name: string }).name,
        "executor_relation": item['executor_person' + (index + 1) + '_relation']
      };
    });

    if (this.state.LeaveGiftData.leave_gift_of_property === "Yes") {
      howManyPeople = {
        "how_many_people_you_leave_property": this.state.LeaveGiftData.propertyPersonsList.length,
      }
    }
    else if (this.state.LeaveGiftData.leave_gift_of_property === "No") {
      howManyPeople = {
        "how_many_people_you_leave_property": null,
      }
    }

    if (this.state.ExemptionData.deliberately_excluding_any_person === "Yes") {
      exemptionPersons = {
        "name_of_excluded_person": this.state.ExemptionData.excluded_person_name,
        "age_of_excluded_person": this.state.ExemptionData.excluded_person_age,
        "address_of_excluded_person": this.state.ExemptionData.excluded_person_address,
      }
    }
    else if (this.state.ExemptionData.deliberately_excluding_any_person === "No") {
      exemptionPersons = null
    }

    if (this.state.PetsData.leave_gift_of_money_for_pet === "Yes") {
      petsMoney = {
        "how_much_money_leave_for_pet": this.state.PetsData.define_money_leave_for_pet,
      }
    }
    else {
      petsMoney = null
    }


    const residueList = this.state.ResidueEstateData.residue_estate_person_info;
    const residueInfos = residueList.map((item, index) => {
      return {
        "residue_estate_name": item['estate_person_name' + (index + 1)],
        "residue_estate_address": item['estate_person_address' + (index + 1)],
        "residue_estate_share_amount": item['estate_person_amount' + (index + 1)]
      };
    });


    let body =
    {
      "bx_block_custom_form_accuwill_infos": {
        ...personalInfobody,
        "executor_infos_attributes": executorConvrtedList,
        "executor_diff_trustees": this.state.ExecutorsData.executor_diff_trustees,
        "executor_reach_certain_age": this.state.ExecutorsData.executor_reach_certain_age,
        "executor_enter_age": this.state.ExecutorsData.executor_reach_certain_age === "Other" ? this.state.ExecutorsData.executor_enter_age : null,
        "specify_funeral_arrangement": this.state.FuneralArrangementData.specify_funeral_arrangement,
        "wish_to_buried_or_cremated": this.state.FuneralArrangementData.specify_buried_or_cremated,
        "state_any_other_funeral_requirement": this.state.FuneralArrangementData.any_other_funeral_requirement,
        "organ_donation_type": this.state.FuneralArrangementData.organs_donation,
        ...organDonation,
        "any_children_under_age_of_18": this.state.AppointingGuardianData.define_children_under_age_of_18,
        ...appointGuardian,
        ...appointGuardianData,
        "leave_any_gift_of_money": this.state.LeaveGiftData.leave_gift_of_money,
        "leave_giftsMoney_attributes": leaveGiftMoneyList,
        "leave_any_specific_gift": this.state.LeaveGiftData.leave_any_specific_gift,
        "leave_gifts_attributes": leaveGiftList,
        "do_you_want_leave_gift_of_property": this.state.LeaveGiftData.leave_gift_of_property,
        ...howManyPeople,
        "gift_properties_attributes": leaveGiftPropertList,
        "what_happen_to_personal_possession_not_specify": this.state.LeaveGiftData.define_when_personal_possession_not_specify,
        "personal_possesions_recipients_attributes": personalPossessionsRecipientList,
        "who_pay_inheritance_tax": this.state.LeaveGiftData.who_pay_inheritance_tax,
        "do_you_want_spouse_get_benefit_from_this_will": this.state.ExemptionData.spouse_get_benifit_from_this_will,
        "should_any_gift_of_children_be_stopped": this.state.ExemptionData.any_gift_of_children_will_fail,
        "are_you_deliberately_excluding_any_person": this.state.ExemptionData.deliberately_excluding_any_person,
        ...exemptionPersons,
        "shall_any_provision_for_pets": this.state.PetsData.provision_for_pets,
        ...provisionData,
        "would_you_leave_gift_of_money_with_pets": this.state.PetsData.leave_gift_of_money_for_pet,
        ...petsMoney,
        "residue_estate": this.state.ResidueEstateData.residue_estate,
        "residue_infos_attributes": residueInfos,
        "confirm_that_this_will_for_myself": this.state.ResidueEstateData.for_Myself,
        "confirm_that_understand_that_i_am_creating_will": this.state.ResidueEstateData.haveMental_Capacity,
        "capacity_vault": this.state.ResidueEstateData.capacity_Vault,
        "order_id": OrderId,
      }
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiCreateCustomForm
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  singleMirroWillData = () => {
    let personalInfobody;
    if (this.state.cartProduct[0]?.attributes?.product_name === "Single Will") {
      personalInfobody = {
        "type_of_will": this.state.cartProduct[0]?.attributes?.product_name,
        "person1_name": this.state.PersonalInfoDataSingle.person_name,
        "person1_age": this.calculateAgeDiff(this.state.PersonalInfoDataSingle.person_age),
        "person1_address": this.state.PersonalInfoDataSingle.person_address,
        "person1_phone_number": (this.state.PersonalInfoDataSingle.person_phone_number).slice(-11),
        "person1_country": this.state.PersonalInfoDataSingle.country_code.name,
        "person1_email": this.state.PersonalInfoDataSingle.person_email,
        "person1_marital_status": this.state.PersonalInfoDataSingle.person_marital_status,
        "person1_confirm_assets": this.state.PersonalInfoDataSingle.person_confirm_assets
      }
    }
    else if (this.state.cartProduct[0]?.attributes?.product_name === "Mirror Will") {
      personalInfobody = {
        "type_of_will": this.state.cartProduct[0]?.attributes?.product_name,
        "person1_name": this.state.PersonalInfoData.person1_name,
        "person1_age": this.calculateAgeDiff(this.state.PersonalInfoData.person1_age),
        "person1_address": this.state.PersonalInfoData.person1_address,
        "person1_phone_number": (this.state.PersonalInfoData.person1_phone_number).slice(-11),
        "person1_country": this.state.PersonalInfoData.person1_country_code.name,
        "person1_email": this.state.PersonalInfoData.person1_email,
        "person1_marital_status": this.state.PersonalInfoData.person1_marital_status,
        "person1_confirm_assets": this.state.PersonalInfoData.person1_confirm_assets,
        "person2_name": this.state.PersonalInfoData.person2_name,
        "person2_age": this.calculateAgeDiff(this.state.PersonalInfoData.person2_age),
        "person2_address": this.state.PersonalInfoData.person2_address,
        "person2_phone_number": (this.state.PersonalInfoData.person2_phone_number).slice(-11),
        "person2_country": this.state.PersonalInfoData.person2_country_code.name,
        "person2_email": this.state.PersonalInfoData.person2_email,
        "person2_marital_status": this.state.PersonalInfoData.person2_marital_status,
        "person2_confirm_assets": this.state.PersonalInfoData.person2_confirm_assets
      }
    }
    return personalInfobody;
  }

  organDonationData = () => {
    let organDonation;
    if (this.state.FuneralArrangementData.organs_donation === "Yes, all organs") {
      organDonation = {
        "organ_use_type": this.state.FuneralArrangementData.Yes_all_organs,
        "specify_organ": null
      }
    }
    else if (this.state.FuneralArrangementData.organs_donation === "Yes, but only... ") {
      organDonation = {
        "organ_use_type": null,
        "specify_organ": this.state.FuneralArrangementData.specific_organs
      }
    }
    else if (this.state.FuneralArrangementData.organs_donation === "Yes, all organs except...") {
      organDonation = {
        "organ_use_type": this.state.FuneralArrangementData.organs_purpose,
        "specify_organ": null
      }
    }
    else if (this.state.FuneralArrangementData.organs_donation === "No") {
      organDonation = {
        "organ_use_type": null,
        "specify_organ": null
      }
    }

    return organDonation;
  }

  leaveGiftDataHandler = () => {
    let leaveGiftList;
    if (this.state.LeaveGiftData.leave_any_specific_gift === "Yes") {
      const giftList = this.state.LeaveGiftData.giftList;
      leaveGiftList = giftList.map((item, index) => {
        return {
          "what_is_gift": item['what_is_gift' + (index + 1)],
          "who_would_you_leave_gift_for": item['define_you_leave_gift' + (index + 1) + '_to'],
          "gift_name": item['gift' + (index + 1) + '_name'],
          "gift_address": item['gift' + (index + 1) + '_address'],
          "gift_phone_number": (item['gift' + (index + 1) + '_phone_number'] as string).slice(-11),
          "gift_country": (item['gift' + (index + 1) + '_country_code'] as { name: string }).name,
          "gift_email": item['gift' + (index + 1) + '_email'],
          "gift_transportation_cost_be_paid": item['gift' + (index + 1) + '_transportation_cost'],
          "gift_inheritance_tax_be_paid": item['gift' + (index + 1) + '_inheritance_cost']
        };
      });
    }
    else if (this.state.LeaveGiftData.leave_any_specific_gift === "No") {
      leaveGiftList = null
    }
    return leaveGiftList;
  }

  leaveGiftMoneyDataHandler = () => {
    let leaveGiftMoneyList;
    if (this.state.LeaveGiftData.leave_gift_of_money === "Yes") {
      const giftMoneyList = this.state.LeaveGiftData.giftMoneyList;
      leaveGiftMoneyList = giftMoneyList.map((item, index) => {
        return {
          "giftMoney_name": item['giftMoney' + (index + 1) + '_name'],
          "giftMoney_address": item['giftMoney' + (index + 1) + '_address'],
          "giftMoney_dob": item['giftMoney' + (index + 1) + '_dob'],
          "who_would_you_leave_giftMoney_for": item['define_you_leave_gift' + (index + 1) + '_to'],
          "giftMoney_phone_number": (item['giftMoney' + (index + 1) + '_phone_number'] as string).slice(-11),
          "giftMoney_country": (item['giftMoney' + (index + 1) + '_country_code'] as { name: string }).name,
          "giftMoney_email": item['giftMoney' + (index + 1) + '_email'],
        };
      });
    }
    else if (this.state.LeaveGiftData.leave_gift_of_money === "No") {
      leaveGiftMoneyList = null
    }
    return leaveGiftMoneyList;
  }

  LeaveRecipientHandler = () => {
    let leaveRecipientList;
    if (this.state.LeaveGiftData.define_when_personal_possession_not_specify === "Pass to specific recipient") {
      const recipientList = this.state.LeaveGiftData.recipientList;
      leaveRecipientList = recipientList.map((item, index) => {
        return {
          "recipient_name": item['recipient' + (index + 1) + '_name'],
          "recipient_address": item['recipient' + (index + 1) + '_address'],
          "recipient_phone_number": (item['recipient' + (index + 1) + '_phone_number'] as string).slice(-11),
          "recipient_country": (item['recipient' + (index + 1) + '_country_code'] as { name: string }).name,
          "recipient_email": item['recipient' + (index + 1) + '_email'],
        };
      });
    }
    else if (this.state.LeaveGiftData.define_when_personal_possession_not_specify != "Pass to specific recipient") {
      leaveRecipientList = null
    }

    return leaveRecipientList;
  }

  LeavePropertyHandler = () => {
    let leaveGiftPropertList;
    if (this.state.LeaveGiftData.leave_gift_of_property === "Yes") {
      const propertyList = this.state.LeaveGiftData.propertyPersonsList;
      leaveGiftPropertList = propertyList.map((item, index) => {
        return {
          "gift_of_property_name": item['gift_of_property' + (index + 1) + '_name'],
          "gift_of_property_address": item['gift_of_property' + (index + 1) + '_address'],
          "gift_of_property_phone_number": (item['gift_of_property' + (index + 1) + '_phone_number'] as string).slice(-11),
          "gift_of_property_country": (item['gift_of_property' + (index + 1) + '_country_code'] as { name: string }).name,
          "gift_of_property_email": item['gift_of_property' + (index + 1) + '_email'],
          "gift_of_property_inheritance_tax_be_paid": item['gift_of_property' + (index + 1) + '_inheritance_tax_be_paid'],
          "gift_of_property_expenses_to_be_paid": item['gift_of_property' + (index + 1) + '_expenses_be_paid'],
          "gift_of_property_include_any_main_residence": item['gift_of_property' + (index + 1) + '_include_any_main_residence'],
          "gift_of_property_include_property_content": item['gift_of_property' + (index + 1) + '_include_property_content']
        };
      });
    }
    else if (this.state.LeaveGiftData.leave_gift_of_property === "No") {
      leaveGiftPropertList = null
    }

    return leaveGiftPropertList;
  }

  handleCloseThankyouModal = () => {
    this.setState({
      openThankyouModal: false
    });
    this.props.history.push({ pathname: "/home-page" })
  };

  nextErrorBtnHandlerPersonMirror = (props: any) => {
    let errObject = props.PersonalInfoDataError;
    let errorList = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: any) => {
      if (item[0] === "person2_country_code" || item[0] === "person1_country_code") {
        return
      }
      else if (item[1] !== "") {
        flag = 1
      }
    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  nextErrorBtnHandlerPersonalSingle = (props: any) => {
    let errObject = props.PersonalInfoDataSingleError;
    let errorList = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: any) => {
      if (item[0] === "country_code") {
        return
      }
      else if (item[1] !== "") {
        flag = 1
      }
    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  nextErrorBtnHandlerExecutors = (props: any) => {
    let errObject = props.ExecutorsDataError;
    let errorList = Object.entries(errObject);
    let flag: number = 0;
    errorList.forEach((item: any) => {
      if (item[0] === "executorsList") {
        let flag1 = item[1].map((obj: {}) => {
          return this.checkErrorFromExecutorList(obj)
        })
        for (const element of flag1) {
          if (element === 1) {
            flag = 1;
            break;
          }
        }
      }
      else if (props.ExecutorsStateData.executor_reach_certain_age == "25" || props.ExecutorsStateData.executor_reach_certain_age == "No") {
        return
      }
      else if (item[1] !== "") {
        flag = 1
      }
    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  checkErrorFromExecutorList = (list: {}) => {
    let errObject = list;
    let errorList = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: any) => {
      if (item[1] !== "") {
        flag = 1;
      }
    });
    return flag;
  }

  nextErrorBtnHandlerFuneral = (props: { [key: string]: { [key: string]: string } }) => {
    let errObject = props.FuneralArrangementDataError;
    let keys = Object.keys(errObject);
    let flag = 0;
    keys.forEach((key: string) => {
      if (props.FuneralArrangementStateData.organs_donation == "Yes, all organs" &&
        (key === "specific_organs" || key === "organs_purpose")) {
        return
      }
      if (props.FuneralArrangementStateData.organs_donation == "Yes, but only..." &&
        (key === "Yes_all_organs" || key === "organs_purpose")) {
        {
          return
        }
      }
      if (props.FuneralArrangementStateData.organs_donation == "Yes, all organs except..." &&
        (key === "Yes_all_organs" || key === "specific_organs")) {
        {
          return
        }
      }
      if (props.FuneralArrangementStateData.organs_donation == "No" &&
        (key === "Yes_all_organs" || key === "specific_organs" || key === "organs_purpose")) {
        {
          return
        }
      }
      if (errObject[key] !== "") {
        flag = 1;
      }
    })

    return this.getFlagValue(flag);

  }

  getFlagValue = (flag: number) => {
    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  nextErrorBtnHandlerAppointing = (props: any) => {
    let errObject = props.AppointingGuardianDataError;
    let errorList = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: any) => {
      if (props.AppointingGuardianStateData.define_children_under_age_of_18 === "No") {
        return
      }

      if (props.AppointingGuardianStateData.appoint_guardian === "No") {
        if (item[0] === "child1_name" || item[0] === "child1_address" || item[0] === "child1_age" || item[0] === "child1_sex" || item[0] === "doyou_require" || item[0] === "wishes_for_guardian") {
          return
        }

      }
      if (item[0] === "person2_country_code" || item[0] === "person1_country_code") {
        return
      }
      if (item[1] !== "") {
        flag = 1
      }
    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  checkAndCondition = (condition1: any, condition2: any) => {
    return condition1 && condition2
  }

  nextErrorBtnHandlerLeaveGift = (props: { [key: string]: {} | any }) => {
    let errObject: Object = props.LeaveGiftDataError;
    let errorList = Object.entries(errObject);
    let flag: boolean = false;
    errorList.forEach((item: any) => {

      if (this.checkAndCondition(props.LeaveGiftStateData.leave_gift_of_money === "Yes", item[0] === "giftMoneyList")) {
        let flag1 = item[1].map((obj: {}) => {
          return this.checkErrorFromGiftList(obj);
        })
        flag = this.giftListCheckErrorHandler(flag1);
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.leave_gift_of_money === "No", item[0] === "giftMoneyList")) {
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.leave_any_specific_gift === "Yes", item[0] === "giftList")) {
        let flag1 = item[1].map((obj: {}) => {
          return this.checkErrorFromGiftList(obj);
        })
        flag = this.giftListCheckErrorHandler(flag1);
        return;
      }


      if (this.checkAndCondition(props.LeaveGiftStateData.leave_any_specific_gift === "No", item[0] === "giftList")) {
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.define_when_personal_possession_not_specify != "Pass to specific recipient", item[0] === "recipientList")) {
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.define_when_personal_possession_not_specify === "Pass to specific recipient", item[0] === "recipientList")) {
        let flag1 = item[1].map((obj: {}) => {
          return this.checkErrorFromGiftList(obj);
        })
        flag = this.giftListCheckErrorHandler(flag1);
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.leave_gift_of_property === "Yes", item[0] === "propertyPersonsList")) {
        let flag2 = item[1].map((obj: {}) => {
          return this.checkErrorFromPropertyList(obj);
        })
        flag = this.giftListCheckErrorHandler(flag2);
        return;
      }

      if (this.checkAndCondition(props.LeaveGiftStateData.leave_gift_of_property === "No", item[0] === "propertyPersonsList")) {
        return;
      }
      if (item[1] !== "") {
        flag = true;
      }
    });

    return flag;
  }

  checkErrorFromGiftList = (list: {}) => {
    let errObject = list;
    let errorList: [string, string][] = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: [string, string]) => {
      if (item[1] !== "") {
        flag = 1;
      }
    });
    return flag;
  }

  checkErrorFromPropertyList = (list: {}) => {
    let errObject = list;
    let errorList: [string, string][] = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: [string, string]) => {
      if (item[1] !== "") {
        flag = 1;
      }
    });
    return flag;
  }

  nextErrorBtnHandlerExemption = (props: any) => {
    let errObject = props.ExemptionDataError;
    let errorList = Object.entries(errObject);
    let flag = 0;
    errorList.forEach((item: any) => {
      if (item[1] !== "") {
        flag = 1
      }
    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  nextErrorBtnHandlerPets = (props: { [key: string]: { [key: string]: string } }) => {
    let errObject = props.PetsDataError;
    let keys = Object.keys(errObject);
    let flag = 0;
    keys.forEach((key: string) => {
      if (props.PetsStateData.provision_for_pets == "No") {
        if (key === "name_of_carer" || key === "address_of_carer" || key === "kind_of_pet" || key === "name_of_pet" || key === "any_pet_care_by_rspca_home") {
          return;
        }
      }
      if (props.PetsStateData.leave_gift_of_money_for_pet == "No") {
        if (key === "define_money_leave_for_pet") {
          return;
        }
      }
      if (errObject[key] !== "") {
        flag = 1;
      }

    });

    if (flag) {
      return true;
    }
    else {
      return false;
    }
  }

  nextErrorBtnHandlerResidue = (props: { [key: string]: { [key: string]: { [key: string]: string }[] } }) => {
    let errObject = props.ResidueEstateDataError;
    let keys = Object.keys(errObject);
    let flag = false;
    keys.forEach((key: string) => {
      if (key == "residue_estate_person_info") {
        let flag1 = errObject[key].map((item: { [key: string]: string }) => {
          return this.estatePersonsErrorCheck(item);
        })
        for (const element of flag1) {
          if (element === 1) {
            flag = true;
          }
        }
        return;
      }
      if (key == "capacity_Vault") {
        return;
      }
      if (errObject[key] as {} !== "") {
        flag = true;
      }
    });

    return flag;
  }

  estatePersonsErrorCheck = (obj: { [key: string]: string }) => {
    let keys = Object.keys(obj);
    let flag = 0;
    keys.forEach((item: string) => {
      if (obj[item] !== "") {
        flag = 1;
      }
    });
    return flag;
  }

  addExecutorsHandler = () => {
    let size = this.state.ExecutorsData.executorsList.length + 1;
    let newList = [...this.state.ExecutorsData.executorsList, {
      ['executor_person' + size + '_name']: "",
      ['executor_person' + size + '_address']: "", ['executor_person' + size + '_relation']: "", ['executor_person' + size + '_phone_number']: "+44",
      ['executor_person' + size + '_country_code']: {
        name: "UK",
        code: "+44",
        path: UKflag
      }
    }];

    let newErrorList = [...this.state.ExecutorsDataError.executorsList, { ['executor_person' + size + '_name']: null, ['executor_person' + size + '_address']: null, ['executor_person' + size + '_relation']: null, ['executor_person' + size + '_phone_number']: null, }];
    this.setState({
      ExecutorsData: {
        ...this.state.ExecutorsData,
        executorsList: newList
      },
      ExecutorsDataError: {
        ...this.state.ExecutorsDataError,
        executorsList: newErrorList
      }
    })
  }

  removeExecutorsHandler = (index: number) => {
    let newList = [...this.state.ExecutorsData.executorsList];
    let newErrorList = [...this.state.ExecutorsDataError.executorsList];

    newList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      ExecutorsData: {
        ...this.state.ExecutorsData,
        executorsList: newList
      },
      ExecutorsDataError: {
        ...this.state.ExecutorsDataError,
        executorsList: newErrorList
      }
    })
  };

  addGiftsHandler = () => {
    let size = this.state.LeaveGiftData.giftList.length + 1;
    let newList = [...this.state.LeaveGiftData.giftList, {
      ['what_is_gift' + size]: "",
      ['define_you_leave_gift' + size + '_to']: "",
      ['gift' + size + '_name']: "",
      ['gift' + size + '_address']: "",
      ['gift' + size + '_phone_number']: "+44",
      ['gift' + size + '_country_code']: {
        name: "UK",
        code: "+44",
        path: UKflag
      },
      ['gift' + size + '_email']: "",
      ['gift' + size + '_transportation_cost']: "Select",
      ['gift' + size + '_inheritance_cost']: "Select",
    }];
    let newErrorList = [...this.state.LeaveGiftDataError.giftList,
    {
      ['what_is_gift' + size]: " ",
      ['define_you_leave_gift' + size + '_to']: " ",
      ['gift' + size + '_name']: " ",
      ['gift' + size + '_address']: " ",
      ['gift' + size + '_phone_number']: "+44",
      ['gift' + size + '_email']: " ",
      ['gift' + size + '_transportation_cost']: " ",
      ['gift' + size + '_inheritance_cost']: " ",
    }];

    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        giftList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        giftList: newErrorList
      }
    })
  }

  addGiftMoneyHandler = () => {
    let size = this.state.LeaveGiftData.giftMoneyList.length + 1;
    let newList = [...this.state.LeaveGiftData.giftMoneyList, {
      ['giftMoney' + size + '_name']: "",
      ['giftMoney' + size + '_address']: "",
      ['giftMoney' + size + '_dob']: "",
      ['define_you_leave_giftMoney' + size + '_to']: " ",
      ['giftMoney' + size + '_phone_number']: "+44",
      ['giftMoney' + size + '_country_code']: {
        name: "UK",
        code: "+44",
        path: UKflag
      },
      ['giftMoney' + size + '_email']: "",
    }];
    let newErrorList = [...this.state.LeaveGiftDataError.giftMoneyList,
    {
      ['giftMoney' + size + '_name']: " ",
      ['giftMoney' + size + '_address']: " ",
      ['giftMoney' + size + '_dob']: " ",
      ['define_you_leave_giftMoney' + size + '_to']: " ",
      ['giftMoney' + size + '_phone_number']: "+44",
      ['giftMoney' + size + '_email']: " ",
    }];

    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        giftMoneyList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        giftMoneyList: newErrorList
      }
    })
  }

  addRecipientHandler = () => {
    let size = this.state.LeaveGiftData.recipientList.length + 1;
    let newRecipientList = [...this.state.LeaveGiftData.recipientList, {
      ['recipient' + size + '_name']: "",
      ['recipient' + size + '_address']: "",
      ['recipient' + size + '_phone_number']: "+44",
      ['recipient' + size + '_country_code']: {
        name: "UK",
        code: "+44",
        path: UKflag
      },
      ['recipient' + size + '_email']: "",
    }];
    let newErrorList = [...this.state.LeaveGiftDataError.recipientList,
    {
      ['recipient' + size + '_name']: " ",
      ['recipient' + size + '_address']: " ",
      ['recipient' + size + '_phone_number']: "+44",
      ['recipient' + size + '_email']: " ",
    }];

    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        recipientList: newRecipientList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        recipientList: newErrorList
      }
    })
  }

  removeGiftsHandler = (index: number) => {
    let newList = [...this.state.LeaveGiftData.giftList];
    let newErrorList = [...this.state.LeaveGiftDataError.giftList];

    newList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        giftList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        giftList: newErrorList
      }
    })
  };

  removeGiftsMoneyHandler = (index: number) => {
    let newList = [...this.state.LeaveGiftData.giftMoneyList];
    let newErrorList = [...this.state.LeaveGiftDataError.giftMoneyList];

    newList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        giftMoneyList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        giftMoneyList: newErrorList
      }
    })
  };

  removeRecipientHandler = (index: number) => {
    let newRecipientList = [...this.state.LeaveGiftData.recipientList];
    let newErrorList = [...this.state.LeaveGiftDataError.recipientList];

    newRecipientList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        recipientList: newRecipientList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        recipientList: newErrorList
      }
    })
  };

  removeProperty = (index: number) => {
    let newList = [...this.state.LeaveGiftData.propertyPersonsList];
    let newErrorList = [...this.state.LeaveGiftDataError.propertyPersonsList];

    newList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        propertyPersonsList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        propertyPersonsList: newErrorList
      }
    })
  }

  addProperty = () => {
    let size = this.state.LeaveGiftData.propertyPersonsList.length + 1;
    let newList = [...this.state.LeaveGiftData.propertyPersonsList, {
      ['gift_of_property' + size + '_name']: "",
      ['gift_of_property' + size + '_address']: "",
      ['gift_of_property' + size + '_phone_number']: "+44",
      ['gift_of_property' + size + '_country_code']: {
        name: "UK",
        code: "+44",
        path: UKflag
      },
      ['gift_of_property' + size + '_email']: "",
      ['gift_of_property' + size + '_inheritance_tax_be_paid']: "Select",
      ['gift_of_property' + size + '_expenses_be_paid']: "Select",
      ['gift_of_property' + size + '_include_any_main_residence']: "Select",
      ['gift_of_property' + size + '_include_property_content']: "Select",
    }];
    let newErrorList = [...this.state.LeaveGiftDataError.propertyPersonsList,
    {
      ['gift_of_property' + size + '_name']: " ",
      ['gift_of_property' + size + '_address']: " ",
      ['gift_of_property' + size + '_phone_number']: "+44",
      ['gift_of_property' + size + '_email']: " ",
      ['gift_of_property' + size + '_inheritance_tax_be_paid']: " ",
      ['gift_of_property' + size + '_expenses_be_paid']: " ",
      ['gift_of_property' + size + '_include_any_main_residence']: " ",
      ['gift_of_property' + size + '_include_property_content']: " ",
    }];

    this.setState({
      LeaveGiftData: {
        ...this.state.LeaveGiftData,
        propertyPersonsList: newList
      },
      LeaveGiftDataError: {
        ...this.state.LeaveGiftDataError,
        propertyPersonsList: newErrorList
      }
    })
  }

  addResiduePersons = () => {
    let size = this.state.ResidueEstateData.residue_estate_person_info.length + 1;
    let newList = [...this.state.ResidueEstateData.residue_estate_person_info,
    {
      ['estate_person_name' + size]: "", ['estate_person_address' + size]: "", ['estate_person_amount' + size]: ""
    }];

    let newErrorList = [...this.state.ResidueEstateDataError.residue_estate_person_info,
    { ['estate_person_name' + size]: "", ['estate_person_address' + size]: "", ['estate_person_amount' + size]: "" }];
    this.setState({
      ResidueEstateData: {
        ...this.state.ResidueEstateData,
        residue_estate_person_info: newList
      },
      ResidueEstateDataError: {
        ...this.state.ResidueEstateDataError,
        residue_estate_person_info: newErrorList
      }
    })
  }

  removeResiduePersons = (index: number) => {
    let newList = [...this.state.ResidueEstateData.residue_estate_person_info];
    let newErrorList = [...this.state.ResidueEstateDataError.residue_estate_person_info];

    newList.splice(index, 1);
    newErrorList.splice(index, 1);
    this.setState({
      ResidueEstateData: {
        ...this.state.ResidueEstateData,
        residue_estate_person_info: newList
      },
      ResidueEstateDataError: {
        ...this.state.ResidueEstateDataError,
        residue_estate_person_info: newErrorList
      }
    })
  };

  giftListCheckErrorHandler = (flag1: any) => {
    let tempFlag = false;
    for (const element of flag1) {
      if (element === 1) {
        tempFlag = true;
        break;
      }
    }
    return tempFlag;
  }

  // Customizable Area End
}
