import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomForm2Controller, {
  Props,
} from "./CustomForm2Controller";
import { NextPreBtns } from "../../../components/src/CommonCustomForms";



export const exe_theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End



export class ExemptionsToYourWill extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, nextBtnHandler,nextErrorBtnHandlerExemption, ExemptionStateData, ExemptionDataHandler, ExemptionDataError } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={exe_theme}>
        <Container maxWidth={"md"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <form>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container spacing={2} className={classes.styleContainerExe}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.headlineColorExe}>
                        Do you want your spouse to benefit from this Will even if divorce / annulment takes place?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleExe}>
                        <StyledSelectFieldExemption 
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={ExemptionStateData.spouse_get_benifit_from_this_will}
                          name="spouse_get_benifit_from_this_will"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                          data-test-id="spouse_get_benifit_from_this_will">
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldExemption>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.spouse_get_benifit_from_this_will !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.spouse_get_benifit_from_this_will}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.headlineColorExe}>
                        Should any gifts to your children be stopped from automatically passing to their own children, should the gift fail?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleExe}>
                        <StyledSelectFieldExemption 
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={ExemptionStateData.any_gift_of_children_will_fail}
                          name="any_gift_of_children_will_fail"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                          data-test-id="any_gift_of_children_will_fail">
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldExemption>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.any_gift_of_children_will_fail !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.any_gift_of_children_will_fail}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.headlineColorExe}>
                        Are you deliberately excluding any person from this Will who may claim against the estate?
                      </Typography>
                      <FormControl variant="filled" className={classes.formControlCustomStyleExe}>
                        <StyledSelectFieldExemption 
                          disableUnderline
                          renderValue={(selected: React.ChangeEvent<HTMLInputElement>) => {
                            return selected;
                          }}
                          value={ExemptionStateData.deliberately_excluding_any_person}
                          name="deliberately_excluding_any_person"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                          data-test-id="deliberately_excluding_any_person">
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </StyledSelectFieldExemption>
                      </FormControl>
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.deliberately_excluding_any_person !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.deliberately_excluding_any_person}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldExemption
                        multiline
                        id="standard-textarea"
                        label="Name of Person you wish to exclude"
                        value={ExemptionStateData.excluded_person_name}
                        name="excluded_person_name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true, className: classes.input }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        data-test-id="excluded_person_name"
                        className={classes.textCustomStyleExe}
                      />
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.excluded_person_name !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.excluded_person_name}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <StyledTextFieldExemption
                        multiline
                        id="standard-textarea"
                        label="Age"
                        value={ExemptionStateData.excluded_person_age}
                        name="excluded_person_age"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        data-test-id="excluded_person_age"
                        className={classes.textCustomStyleExe}
                      />
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.excluded_person_age !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.excluded_person_age}</div>}
                      </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <StyledTextFieldExemption
                        multiline
                        id="standard-textarea"
                        label="Address"
                        value={ExemptionStateData.excluded_person_address}
                        name="excluded_person_address"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { ExemptionDataHandler?.(e) }}
                        type="text"
                        InputProps={{ disableUnderline: true, className: classes.input }}
                        InputLabelProps={{
                          style: { color: "rgba(0, 0, 0, 0.54)" },
                        }}
                        data-test-id="excluded_person_address"
                        className={classes.textCustomStyleExe}
                      />
                      <Box className={classes.errorMessage}>
                        {ExemptionDataError.excluded_person_address !== "" && <div className={classes.errorTextStyle}>{ExemptionDataError.excluded_person_address}</div>}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                {
                  NextPreBtns({
                    PropValues:this.props,
                    nextBtnHandler:nextBtnHandler,
                    prevPage:"LeaveaGift",
                    nextPage:"Pets",
                    nextErrorBtnHandler:nextErrorBtnHandlerExemption
                  })
                }
              </Grid>
            </form>
          </Grid>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const useStyle_exemption = (exe_theme: any) => ({

  textCustomStyleExe: {
    border: "1px solid #D3D3D3",
    padding: "6px"
  },
  headLineExe: {
    padding: "10px",
    color: "rgba(0, 0, 0, 0.54)"
  },
  btnStyle: {
    backgroundColor: "#364F6B",
    color: "#ffffff",
    width: "20%",
    padding: "10px",
    margin: "8px 8px 26px 8px",
  },
  formControlCustomStyleExe: {
    display: "flex",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    "& div": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    },
    "& div:focus": {
      borderRadius: "8px",
      backgroundColor: "#ffffff !important",
    }
  },
  styleContainerExe: {
    alignItems: "flex-end"
  },
  headlineColorExe: {
    padding: "2% 0",
    color: "#000 !important"
  },
  btnCollection: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5%"
  },
  errorTextStyle: {
    color: "red",
    fontSize: "0.9rem"
  },
  errorMessage: {
    height: "1rem",
  }
});

export const StyledTextFieldExemption: any = withStyles({
  root: {
    fontSize: "14",
    borderRadius: "8px",
    border: "1px solid #D3D3D3",
    backgroundColor: "#ffffff !important",
    display: "block !important",
    "& #standard-textarea-label": {
      padding: "0px 0px 0px 10px",
    },
    "& .Mui-focused": {
      padding: "10px 0px 0px 10px !important",
    },
    "& #standard-textarea": {
      color: "#000 !important"
    }

  },
})((props: any) => <TextField {...props} />);

export const StyledSelectFieldExemption: any = withStyles({
  root: {
    border: "1px solid #D3D3D3 !important",
    borderRadius: "8px",
    backgroundColor: "#ffffff !important",
    height: "auto",
    padding: "21.5px 10px !important"
  }
})((props: any) => <Select {...props} />);

export default ExemptionsToYourWill;
// Customizable Area End
